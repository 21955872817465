import { http_post_method, http_get_method } from '../utils/common-http'; 
import { Item as ItemType } from '../types/item';
import { CustomerItemMap as CustomerItemMapType } from '../types/Customer';
import { SupplierItemMap as SupplierItemMapType } from '../types/Supplier';

export const SaveItemData = async (ItemData: ItemType) => {
    const response = await http_post_method(`api/Item`, ItemData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetItemData = async (pk_itemid: number) => {
    const response = await http_get_method(`api/Item`, { pk_itemId: pk_itemid });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const DeleteItems = async (pk_itemId: number) => {
    const response = await http_get_method(`api/Item/DeleteItems`, { pk_itemId: pk_itemId });
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetSupplierItemMap = async (pk_SupplierId: number) => {
    const response = await http_get_method(`api/Item/SupplierItemMap`, { pk_SupplierId: pk_SupplierId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GetCustomerItemMap = async (pk_CustomerId: number) => {
    const response = await http_get_method(`api/Item/CustomerItemMap`, { pk_CustomerId: pk_CustomerId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const SaveCustomerItemData = async (ItemData: CustomerItemMapType) => {
    const response = await http_post_method(`api/Item/CustomerItemMap`, ItemData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const SaveSupplierItemData = async (ItemData: SupplierItemMapType) => {
    const response = await http_post_method(`api/Item/SupplierItemMap`, ItemData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}