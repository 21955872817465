import React, { ComponentType, useState, useEffect } from 'react';
import { Grid, IconButton, ListItemIcon, Container, Divider, CardHeader, CardContent } from '@mui/material';
import { DivBox, InfoBox } from '../../StyledComponents/styled';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import { GetSupplierData } from "../../services/supplier-service";
import { GetItemData, SaveSupplierItemData, GetSupplierItemMap } from "../../services/item-service";
import Select from 'react-select'
import { SupplierItemMap as SupplierItemMapType } from '../../types/Supplier';
import toast, { Toaster } from 'react-hot-toast';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from "react-hook-form";

export const SupplierItemMap = () => {

  const [supplierlist, setSupplierlist] = useState([]);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [itemlist, setItemlist] = useState([]);

  const [ItemData, setItemData] = useState<SupplierItemMapType>({
    ID: 0,
    MappedItems: [{
      pk_itemid: 0
    }],
  });

  useEffect(() => {
    const loadSuppliers = async () => {
      const itemsdata = await GetSupplierData('');
      setSupplierlist(itemsdata)
    }
    const loadItems = async () => {
      const itemsdata = await GetItemData(0);
      setItemlist(itemsdata.Table)
    }
    loadItems();
    loadSuppliers();
  }, []);

  const handleSupplierSelect = async (event) => {
    if (ItemData != undefined) {
      ItemData.ID = event.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    const itemsdata = await GetSupplierItemMap(event.value);
    if (itemsdata.length > 0) {
      newData.MappedItems = itemsdata;
    }
    else {
      newData.MappedItems = [{
        pk_itemid: 0
      }];
    }
    setItemData(newData);
  };

  const handleItemSelect = (event, key) => {
    if (ItemData != undefined) {
      ItemData.MappedItems[key].pk_itemid = event.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  };
  
  const handleDeleteRow = (key) => {
    let newData = JSON.parse(JSON.stringify(ItemData));
    newData.MappedItems.splice(key, 1);
    setItemData(newData);
  }

  const handleAddRow = () => {
    const newData = JSON.parse(JSON.stringify(ItemData));
    newData.MappedItems.push({
      pk_itemid: 0
    })
    setItemData(newData);
  }

  const handleSaveClick = async () => {
    let data = await SaveSupplierItemData(ItemData);
    if (data != undefined && data.succeeded) {
      toast.success('Successfully Updated Supplier Item Map!');
      setItemData({
        ID: 0,
        MappedItems: [{
          pk_itemid: 0
        }],
      });
    }
    else {
      toast.error('Error in creating Supplier Item Map please try again!');
    }
  }  

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DivBox>
            <CardHeader
              action={
                <ListItemIcon>
                  <IconButton size="large">
                    <SaveIcon onClick={handleSubmit(handleSaveClick)} />
                  </IconButton>
                </ListItemIcon>
              }
              title="Supplier Item Mapping"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {supplierlist != undefined && supplierlist.length > 0 &&
                      <Select
                        placeholder={<div>Type to search supplier</div>}
                        options={supplierlist}
                        value={supplierlist.filter(function (option) {
                          return option.value === ItemData.ID;
                        })}
                        onChange={(event) => handleSupplierSelect(event)} />
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <table className={"table"}>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Item</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {ItemData?.MappedItems.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>
                                {itemlist != undefined && itemlist.length > 0 &&
                                  <Select
                                    options={itemlist}
                                    value={itemlist.filter(function (option) {
                                      return option.value === val.pk_itemid;
                                    })}
                                    onChange={(event) => handleItemSelect(event, key)} />
                                }
                              </td>
                              <th><input type="button" className="btn btn-danger" value="Delete" onClick={() => handleDeleteRow(key)} /></th>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={5}><input type="button" className="btn btn-danger" value="Add New Row" onClick={handleAddRow} /></td>
                        </tr>
                      </tfoot>
                    </table>
                  </Grid>
                </Grid>
              </Container>
            </CardContent>
          </DivBox>
        </Grid>
      </Grid>      
      <Toaster />
    </>
  );
};