import { http_post_method, http_get_method, http_download_method } from '../utils/common-http'; 
import { Receipt as ReceiptType } from '../types/Receipt';

export const SaveReceiptData = async (ReceiptData: ReceiptType) => {
    const response = await http_post_method(`api/Receipt`, ReceiptData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetReceiptData = async (pk_ReceiptId: number) => {
    const response = await http_get_method(`api/Receipt/GetReceiptData`, { pk_ReceiptId: pk_ReceiptId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const DeleteReceipt = async (pk_paymentreceiptid: number) => {
    const response = await http_get_method(`api/Receipt/DeleteReceipt`, { pk_paymentreceiptid: pk_paymentreceiptid });
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetInvoiceDataForReceipt = async (pk_InvoiceId: number) => {
    const response = await http_get_method(`api/Receipt/GetInvoiceDataForReceipt`, { pk_InvoiceId: pk_InvoiceId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GetInvoiceListForCustomer = async (pk_customerid: number) => {
    const response = await http_get_method(`api/Receipt/GetInvoiceListForCustomer`, { pk_customerid: pk_customerid });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}