import React from 'react';

import { ComponentType, useState, useEffect } from 'react';
import {
  lighten, Icon, styled, Grid, IconButton, ListItemIcon,
  Divider, Container, Card, CardActionArea, CardContent, Typography, Box, CardHeader
} from '@mui/material';
import { DivBox, InfoBox } from '../../StyledComponents/styled';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import GridView from '../../components/GridView/index';
import Insights from '../../components/Insights/index';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select'
import { Invoice as InvoiceType } from '../../types/Invoice';
import { get_financial_year } from '../../utils/common';
import { GetDCForInvoice } from "../../services/dc-service";
import { SaveInvoiceData, GetInvoiceData, GenerateReport, DeleteInvoice, GetLatestWorkorderForCustomer } from "../../services/invoice-service";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GetItemData } from "../../services/item-service";
import { GetCustomerData } from "../../services/customer-service";
import { GetWorkOrderList } from "../../services/work-order-service";
import dayjs, { Dayjs } from 'dayjs';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";

export const Invoice = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [customerlist, setCustomerlist] = useState([]);
  const [itemlist, setItemlist] = useState([]);
  const [workOrderList, setWorkOrderList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedMode, setSelectedMode] = useState<String>('History');
  const [InvoiceData, setInvoiceData] = useState<InvoiceType>({
    pk_InvoiceId: 0,
    InvoiceNo: '',
    InvoiceDate: new Date(),
    pk_CustomerId: 0,
    CustomerAddr: '',
    WorkOrderNo: '',
    WorkOrderDate: new Date(),
    PONo: '',
    PODate: new Date(),
    RGBNo: '',
    RGBDate: '',
    CustDCNo: '',
    CustDCDate: new Date(),
    F4No: '',
    F4Date: '',
    Remarks: '',
    InvoiceItems: [{
      pk_itemId: 0,
      ItemRate: 0,
      pk_workorderid: 0,
      DCNo: '',
      CustDCNo: '',
      PONo: '',
      QtyRecieved: 0,
      value: 0,
      taxvalue: 0,
      total: 0
    }],
  });

  const loadInvoice = async () => {
    const invoicesList = await GetInvoiceData();
    const finYear = get_financial_year();
    const invoicesListForFY = invoicesList.filter(function (row) {
      return row.label.endsWith(finYear);
    });

    let invCnt = 0;

    if (invoicesListForFY.length > 0) {
      const invoiceno = invoicesListForFY.reduce(function (prev, current) {
        return (prev.id > current.id) ? prev : current
      }).label;
      invCnt = parseInt(invoiceno.substring(0,invoiceno.indexOf('/')));
    }

    const incNo = (100001 + invCnt).toString();
    const WONo = incNo.substring(1, incNo.length) + '/' + finYear;
    InvoiceData.InvoiceNo = WONo;
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
    setInvoiceList(invoicesList)
  }

  useEffect(() => {
    const loadCustomers = async () => {
      const itemsdata = await GetCustomerData('');
      setCustomerlist(itemsdata)
    }
    const loadItems = async () => {
      const itemsdata = await GetItemData(0);
      setItemlist(itemsdata.Table)
    }
    const loadWorkOrder = async () => {
      const workOrdersList = await GetWorkOrderList(0);
      setWorkOrderList(workOrdersList)
    }
    loadItems();
    loadCustomers();
    loadWorkOrder();
    loadInvoice();
  }, []);

  const handleInfoModeChange = (mode: String) => {
    setSelectedMode(mode);
  }

  const handleCustomerSelect = async (event) => {
    if (InvoiceData != undefined) {
      InvoiceData.pk_CustomerId = event.value;
    }
    
    const dcData = await GetDCForInvoice(event.value);
    const workOrderData = await GetLatestWorkorderForCustomer(event.value);
    if (dcData.length > 0) {
      const InvoiceItems = [];
      for (let i = 0; i < dcData.length; i++) {
        const value = (parseFloat(dcData[i].ItemRate) * parseFloat(dcData[i].DespatchQty));
        const taxvalue = (value * 0.12);
        InvoiceItems.push(
          {
            pk_itemId: dcData[i].pk_ItemID,
            ItemRate: dcData[i].ItemRate.toFixed(2),
            QtyRecieved: dcData[i].DespatchQty,
            value: value.toFixed(2),
            taxvalue: taxvalue.toFixed(2),
            pk_workorderid: dcData[i].pk_workorderid,
            DCNo: dcData[i].DCNo,
            CustDCNo: dcData[i].CustDCNo,
            PONo: dcData[i].PONo,
            total: (value+taxvalue).toFixed(2)
          }
        )
      }
      InvoiceData.CustDCNo = dcData[0].DCNo;
      InvoiceData.CustDCDate = new Date(dcData[0].DCDate);
      InvoiceData.PODate = new Date(dcData[0].PODate);
      InvoiceData.PONo = workOrderData[0]?.PONo;
      InvoiceData.InvoiceItems = InvoiceItems;
    }
    else {
      InvoiceData.CustDCNo = '';
      InvoiceData.CustDCDate = new Date();
      InvoiceData.PODate = new Date();
      InvoiceData.PONo = '';
      InvoiceData.InvoiceItems = [{
        pk_itemId: 0,
        ItemRate: 0,
        pk_workorderid: 0,
        DCNo: '',
        CustDCNo: '',
        PONo: '',
        QtyRecieved: 0,
        value: 0,
        taxvalue: 0,
        total: 0
      }];
    }

    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  };

  const handleInvoiceNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InvoiceData != undefined) {
      InvoiceData.InvoiceNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  }

  const handleRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InvoiceData != undefined) {
      InvoiceData.Remarks = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  }

  const handleInvoiceDateChange = (newValue) => {
    if (InvoiceData != undefined) {
      InvoiceData.InvoiceDate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  }

  const handleItemSelect = (event, key) => {
    if (InvoiceData != undefined) {
      InvoiceData.InvoiceItems[key].pk_itemId = event.value;
    }
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  };

  const handleQuantityRecievedChange = (event, key) => {
    if (InvoiceData != undefined) {
      InvoiceData.InvoiceItems[key].QtyRecieved = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  };

  const handleValueChange = (event, key) => {
    if (InvoiceData != undefined) {
      InvoiceData.InvoiceItems[key].value = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  };

  const handleTaxValueChange = (event, key) => {
    if (InvoiceData != undefined) {
      InvoiceData.InvoiceItems[key].taxvalue = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  };

  const handleTotalChange = (event, key) => {
    if (InvoiceData != undefined) {
      InvoiceData.InvoiceItems[key].total = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    setInvoiceData(newData);
  };

  const handleDeleteRow = (key) => {
    let newData = JSON.parse(JSON.stringify(InvoiceData));
    newData.InvoiceItems.splice(key, 1);
    setInvoiceData(newData);
  }

  const handleAddRow = () => {
    const newData = JSON.parse(JSON.stringify(InvoiceData));
    newData.InvoiceItems.push({
      pk_itemId: 0,
      ItemRate: 0,
      pk_workorderid: 0,
      DCNo: '',
      PONo: '',
      QtyRecieved: 0,
      QtyDelivered: 0,
      QtyBalance: 0,
      Desc: ''
    })
    setInvoiceData(newData);
  }

  const handleSaveClick = async () => {
    const filterInvoiceItemWithDesQty = InvoiceData.InvoiceItems.filter(function (el) {
      return el.QtyRecieved > 0
    });
    if (filterInvoiceItemWithDesQty.length > 0) {
      const data = await SaveInvoiceData(InvoiceData);
      if (data != undefined && data.succeeded) {
        toast.success('Invoice Saved Successfully!');
        loadInvoice();
        setInvoiceData({
          pk_InvoiceId: 0,
          InvoiceNo: '',
          InvoiceDate: new Date(),
          pk_CustomerId: 0,
          CustomerAddr: '',
          WorkOrderNo: '',
          WorkOrderDate: new Date(),
          PONo: '',
          PODate: new Date(),
          RGBNo: '',
          RGBDate: '',
          CustDCNo: '',
          CustDCDate: new Date(),
          F4No: '',
          F4Date: '',
          Remarks: '',
          InvoiceItems: [{
            pk_itemId: 0,
            ItemRate: 0,
            pk_workorderid: 0,
            DCNo: '',
            CustDCNo: '',
            PONo: '',
            QtyRecieved: 0,
            value: 0,
            taxvalue: 0,
            total: 0
          }],
        });
      }
      else {
        toast.error('Error in creating Invoice please try again!');
      }
    }
    else {
      toast.error('Please add some items before saving!');
    }
  }

  const handleClearAll = () => {
    toast.success('Invoice Data Cleared!');
    setInvoiceData({
      pk_InvoiceId: 0,
      InvoiceNo: '',
      InvoiceDate: new Date(),
      pk_CustomerId: 0,
      CustomerAddr: '',
      WorkOrderNo: '',
      WorkOrderDate: new Date(),
      PONo: '',
      PODate: new Date(),
      RGBNo: '',
      RGBDate: '',
      CustDCNo: '',
      CustDCDate: new Date(),
      F4No: '',
      F4Date: '',
      Remarks: '',
      InvoiceItems: [{
        pk_itemId: 0,
        ItemRate: 0,
        pk_workorderid: 0,
        DCNo: '',
        CustDCNo: '',
        PONo: '',
        QtyRecieved: 0,
        value: 0,
        taxvalue: 0,
        total: 0
      }],
    });
  }

  const handleHistoryOnclick = async (id) => {
    const itemsdata = await GetInvoiceData();
    setInvoiceData(itemsdata[0]);
  }

  const handleDownloadReportOnclick = async (id) => {
    GenerateReport(id);
  }

  const handleDeleteInvoiceOnclick = async (id) => {
    const data = await DeleteInvoice(id);
    if (data != undefined && data.succeeded) {
      toast.success('Invoice Deleted!');
      loadInvoice();
    }
  }

  const columns = [
  {
      field: 'inv-no',
      headerName: 'Invoice No',
      width: 150,
      renderCell: (params) => (
        <span>
          {params.row.label}
        </span>
      )
    },
    {
      field: 'report',
      headerName: 'Report',
      width: 150,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDownloadReportOnclick(params.row.value)} className="btn btn-link">
            Download Report
          </button>
        </>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDeleteInvoiceOnclick(params.row.value)} className="btn btn-link">
            Delete Invoice
          </button>
        </>
      )
    }
  ];

  return (
    <>

      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DivBox>
            <CardHeader
              action={
                <ListItemIcon>
                  <IconButton size="large">
                    <SaveIcon onClick={handleSubmit(handleSaveClick)} />
                  </IconButton>
                  <IconButton size="large">
                    <ClearAllIcon onClick={handleClearAll} />
                  </IconButton>
                </ListItemIcon>
              }
              title="Invoice Entry"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          placeholder={<div>Type to search customer</div>}
                          options={customerlist}
                          value={customerlist.filter(function (option) {
                            return option.value === InvoiceData.pk_CustomerId;
                          })}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={(event) => handleCustomerSelect(event)} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="P.O. No."
                        value={InvoiceData?.PONo}
                        sx={{ m: 1, width: '100%' }}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Invoice No."
                        value={InvoiceData?.InvoiceNo}
                        onChange={handleInvoiceNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Invoice Date"
                        inputFormat="DD/MM/YYYY"
                        value={InvoiceData?.InvoiceDate}
                        onChange={handleInvoiceDateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        disabled
                        id="outlined-required"
                        label="Address"
                        value={InvoiceData?.CustomerAddr}
                        multiline
                        rows={1}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-required"
                        label="Remarks"
                        value={InvoiceData?.Remarks}
                        multiline
                        rows={1}
                        onChange={handleRemarksChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="P.O. No."
                        value={InvoiceData?.PONo}
                        onChange={handlePONoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="P.O. Date"
                        inputFormat="DD/MM/YYYY"
                        value={InvoiceData?.PODate}
                        onChange={handlePODateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Work Order No."
                        value={InvoiceData?.WorkOrderNo}
                        onChange={handleWorkOrderNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Work Order Date"
                        inputFormat="DD/MM/YYYY"
                        value={InvoiceData?.WorkOrderDate}
                        onChange={handleWorkOrderDateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Cust. D.C. No."
                        value={InvoiceData?.CustDCNo}
                        onChange={handleCustDCNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Cust DC Date"
                        inputFormat="DD/MM/YYYY"
                        value={InvoiceData?.CustDCDate}
                        onChange={handleCustDCDateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid> */}
                  <div style={{ overflowX: 'scroll' }}>
                    <table className={"table table-invoice"} style={{ width: '2000px' }}>
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>S.No</th>
                          <th style={{ width: "10%" }}>WO No</th>
                          <th style={{ width: "5%" }} className='sticky-1'>DC No</th>
                          <th style={{ width: "5%" }} className='sticky-2'>Cust DC No</th>
                          <th style={{ width: "20%" }}>Item</th>
                          <th style={{ width: "5%" }}>Item Rate</th>
                          <th style={{ width: "5%" }}>Quantity Recieved</th>
                          <th style={{ width: "5%" }}>Value</th>
                          <th style={{ width: "5%" }}>12% of Tax</th>
                          <th style={{ width: "5%" }}>Total</th>
                          <th style={{ width: "5%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {InvoiceData?.InvoiceItems.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>
                                {workOrderList != undefined && workOrderList.length > 0 &&
                                  <Select         
                                    isDisabled={true}
                                    options={workOrderList}
                                    value={workOrderList.filter(function (option) {
                                      return option.value === val.pk_workorderid;
                                    })} />
                                }
                              </td>
                              <td className='sticky-1'><input type="text" className="form-control" value={val.DCNo} disabled /></td>
                              <td className='sticky-2'><input type="text" className="form-control" value={val.CustDCNo} disabled /></td>
                              <td>
                                {itemlist != undefined && itemlist.length > 0 &&
                                  <Select
                                    isDisabled={true}
                                    options={itemlist}
                                    value={itemlist.filter(function (option) {
                                      return option.value === val.pk_itemId;
                                    })}
                                    onChange={(event) => handleItemSelect(event, key)} />
                                }
                              </td>
                              <td><input type="text" className="form-control" value={val.ItemRate} /></td>
                              <td><input type="text" className="form-control" value={val.QtyRecieved} onChange={(event) => handleQuantityRecievedChange(event, key)} /></td>
                              <td><input type="text" className="form-control" value={val.value} onChange={(event) => handleValueChange(event, key)} /></td>
                              <td><input type="text" className="form-control" value={val.taxvalue} onChange={(event) => handleTaxValueChange(event, key)} /></td>
                              <td><input type="text" className="form-control" value={val.total} onChange={(event) => handleTotalChange(event, key)} /></td>
                              <td><input type="button" className="btn btn-danger" value="Delete" onClick={() => handleDeleteRow(key)} /></td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={10}><input type="button" className="btn btn-danger" value="Add New Row" onClick={handleAddRow} /></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </Container>
              </LocalizationProvider>
            </CardContent>
          </DivBox>
        </Grid>
        <Grid item xs={12} md={2}>
          <InfoBox>
            <ListItemIcon style={{ margin: '15px', minWidth: '35px' }}>
              <StyledIconButton size="small" isSelected={selectedMode == 'History' ? true : false} onClick={() => handleInfoModeChange("History")}>
                <StyledIcon component={ManageSearchIcon} isSelected={selectedMode == 'History' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon>
              <StyledIconButton size="small" isSelected={selectedMode == 'Insights' ? true : false} onClick={() => handleInfoModeChange("Insights")}>
                <StyledIcon component={InsightsIcon} isSelected={selectedMode == 'Insights' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon style={{ float: 'right', marginTop: '8px' }}>
              <IconButton size="large">
                <AspectRatioIcon />
              </IconButton>
            </ListItemIcon>
            <Divider />
            {selectedMode == 'History' ? <GridView columns={columns} rows={invoiceList} /> : <Insights />}
          </InfoBox>
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;