import { ComponentType, useState, useEffect } from 'react';
import {
  lighten, Icon, styled, Grid, IconButton, Button,
  Divider, CardContent, CardHeader
} from '@mui/material';
import { DivBox } from '../../StyledComponents/styled';
import TextField from '@mui/material/TextField';
import GridViewReport from '../../components/GridViewReport/index';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select'
import { StatementOfSales, StatementOfSummary, GetItemHistoryDetails, StatementOfStock } from "../../services/reports-service";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GetCustomerData } from "../../services/customer-service";
import { Toaster } from 'react-hot-toast';
import * as XLSX from 'xlsx';

export const Reports = () => {

  const [reportsType, setReportsType] = useState('Statement Of Sales');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [pk_CustomerId, setPk_CustomerId] = useState(0);
  const [customerlist, setCustomerlist] = useState([]);
  const [columns, setColumns] = useState([]);
  const [list, setList] = useState([]);
  const [reportTitle, setReportTitle] = useState('');
  const [reportHeaders, setReportHeaders] = useState([]);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    const loadCustomers = async () => {
      const itemsdata = await GetCustomerData('');
      setCustomerlist(itemsdata)
    }
    loadCustomers();
  }, []);

  const report_types = [
    { value: 'Statement Of Sales', label: 'Statement Of Sales' },
    { value: 'Statement Of Summary', label: 'Statement Of Summary' },
    { value: 'Statement Of Stock', label: 'Statement Of Stock' }
  ];

  const handleReportsTypeChange = (event) => {
    setReportsType(event.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(new Date(event));
  };

  const handleEndDateChange = (event) => {
    setEndDate(new Date(event));
  };

  const handleCustomerSelect = (event) => {
    setPk_CustomerId(event.value);
  };

  const getItemHistoryDetails = async (id) => {
    const itemsdata = await GetItemHistoryDetails(id, pk_CustomerId);
  };

  const handleGenerate = async () => {
    if (reportsType == "Statement Of Sales") {
      const data = await StatementOfSales(pk_CustomerId, startDate, endDate);
      setReportTitle("Statement of Sales");
      setReportHeaders(["Inv No", "Inv Date", "Customer Name", "Base Value", "SGST", "CGST", "Total Amt."]);

      setReportData(data.map(elt => [elt.InvoiceNo, elt.InvDate,
      elt.name, elt["Base Value"],
      elt.SGST, elt.CGST,
      elt["Total Amt"]]));

      setColumns([
        {
          field: 'InvoiceNo',
          headerName: 'Inv No',
          width: 150,
          renderCell: (params) => (
            <span>
              {params.row['InvoiceNo']}
            </span>
          )
        },
        {
          field: 'InvDate',
          headerName: 'Inv Date',
          width: 150,
          renderCell: (params) => (
            <span>
              {params.row['InvDate']}
            </span>
          )
        },
        {
          field: 'name',
          headerName: 'Customer Name',
          width: 500,
          renderCell: (params) => (
            <span>
              {params.row['name']}
            </span>
          )
        },
        {
          field: 'Base Value',
          headerName: 'Base Value',
          width: 150,
          renderCell: (params) => (
            <span>
              {params.row['Base Value']}
            </span>
          )
        },
        {
          field: 'SGST',
          headerName: 'SGST',
          width: 150,
          renderCell: (params) => (
            <span>
              {params.row['SGST']}
            </span>
          )
        },
        {
          field: 'CGST',
          headerName: 'CGST',
          width: 150,
          renderCell: (params) => (
            <span>
              {params.row['CGST']}
            </span>
          )
        },
        {
          field: 'Total Amt',
          headerName: 'Total Amt.',
          width: 150,
          renderCell: (params) => (
            <span>
              {params.row['Total Amt']}
            </span>
          )
        }
      ]);
      setList(data);
    } else if (reportsType == "Statement Of Summary") {
      const data = await StatementOfSummary(pk_CustomerId, startDate, endDate);
      setReportTitle("Statement of Summary");
      setReportHeaders(["Part No", "Description", "OP. Bal.", "REC", "ISS", "BAL"]);

      setReportData(data.map(elt => [elt.part_no, elt.item_name,
      elt.OpeningBalance, elt.QtyRecieved,
      elt.DespatchQty, elt.Balance]));

      setColumns([
        {
          field: 'part_no',
          headerName: 'Part No',
          width: 150,
          resizable: true,
          renderCell: (params) => (
            <button onClick={() => getItemHistoryDetails(params.row['part_no'])} className="btn btn-link">
              details
            </button>
          )
        },
        {
          field: 'part_no',
          headerName: 'Part No',
          width: 150,
          resizable: true,
          renderCell: (params) => (
            <span>
              {params.row['part_no']}
            </span>
          )
        },
        {
          field: 'item_name',
          headerName: 'Description',
          width: 500,
          resizable: true,
          renderCell: (params) => (
            <span>
              {params.row['item_name']}
            </span>
          )
        },
        {
          field: 'OpeningBalance',
          headerName: 'OP. Bal.',
          width: 150,
          resizable: true,
          renderCell: (params) => (
            <span>
              {params.row['OpeningBalance']}
            </span>
          )
        },
        {
          field: 'QtyRecieved',
          headerName: 'REC',
          width: 150,
          resizable: true,
          renderCell: (params) => (
            <span>
              {params.row['QtyRecieved']}
            </span>
          )
        },
        {
          field: 'DespatchQty',
          headerName: 'ISS',
          width: 150,
          resizable: true,
          renderCell: (params) => (
            <span>
              {params.row['DespatchQty']}
            </span>
          )
        },
        {
          field: 'Balance',
          headerName: 'BAL',
          width: 150,
          resizable: true,
          renderCell: (params) => (
            <span>
              {params.row['Balance']}
            </span>
          )
        }
      ]);
      setList(data);
    } else if (reportsType == "Statement Of Stock") {
      setReportTitle("Statement of Stock");
      await StatementOfStock(pk_CustomerId, startDate, endDate);
    }
  };

  const handleGenerateExcel = async () => {
    const data = await StatementOfSales(pk_CustomerId, startDate, endDate);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "StatementofSales.xlsx");
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <DivBox>
            <CardHeader
              title="Reports Section"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <FormControl fullWidth style={{ margin: '8px' }}>
                      <Select
                        placeholder={<div>Please select Reports Type</div>}
                        options={report_types}
                        value={report_types.filter(function (option) {
                          return option.value === reportsType;
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        onChange={(event) => handleReportsTypeChange(event)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth style={{ margin: '8px' }}>
                      <Select
                        placeholder={<div>Type to search customer</div>}
                        options={customerlist}
                        value={customerlist.filter(function (option) {
                          return option.value === pk_CustomerId;
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        onChange={(event) => handleCustomerSelect(event)} />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <DatePicker
                      label="Start Date"
                      inputFormat="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <DatePicker
                      label="End Date"
                      inputFormat="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant="contained" style={{ marginTop: '15px' }} onClick={handleGenerate}>Generate</Button>
                  </Grid>
                  {reportsType == "Statement Of Sales" ? <Grid item xs={2}>
                    <Button variant="contained" style={{ marginTop: '15px' }} onClick={handleGenerateExcel}>Generate Excel</Button>
                  </Grid> : null}
                </Grid>
                <Grid container spacing={1}>
                  <GridViewReport columns={columns} rows={list} reportTitle={reportTitle} reportHeaders={reportHeaders} reportData={reportData} />
                </Grid>
              </LocalizationProvider>
            </CardContent>
          </DivBox>
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;