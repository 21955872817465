import { http_post_method, http_get_method } from '../utils/common-http'; 
import { WorkOrder as WorkOrderType } from '../types/WorkOrder';

export const SaveWorkOrderData = async (WorkOrderData: WorkOrderType) => {
    const response = await http_post_method(`api/WorkOrder`, WorkOrderData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetWorkOrderList = async (pk_CustomerId: number) => {
    const response = await http_get_method(`api/WorkOrder`, { pk_CustomerId: pk_CustomerId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GetWorkOrderData = async (pk_workorderid: number) => {
    const response = await http_get_method(`api/WorkOrder/GetWorkOrderData`, { pk_workorderid: pk_workorderid });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const DeleteWorkOrder = async (pk_workorderid: number) => {
    const response = await http_get_method(`api/WorkOrder/DeleteWorkOrder`, { pk_workorderid: pk_workorderid });
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}