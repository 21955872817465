import React, { useState } from "react";
import loginImg from "../../../../images/login.svg";
import { useHistory } from "react-router-dom";
import { ValidateLogin } from "../../../../services/login-service";
import { useDispatch } from 'react-redux';
import { common } from '../../../../reducers/commonreducer';

export const Login = ({alert, togglePage}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  let history = useHistory();

  const dispatch = useDispatch();

  const handleUsernameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setUsername(newValue)
  }

  const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setPassword(newValue)
  }

  const validateLogin = async () => {
    dispatch(common({ isLoading: true }));
    const isValid = await ValidateLogin(username, password, dispatch);
    if (isValid) {
      history.push("/master/customer");
      alert("Login Successful.!", "success");
    }
    else {
      alert("Invalid Username or Password.!!", "error");
    }
    dispatch(common({ isLoading: false }));
  }

  const registerPage = async () => {
    togglePage();
  }

  return (
    <div className="base-container">  
      <div className="header">Login</div>
      <div className="content">
        <div className="image">
          <img src={loginImg} alt="" />
        </div>
        <div className="form">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" name="username" placeholder="username" value={username} onChange={handleUsernameChange} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" placeholder="password" value={password} onChange={handlePasswordChange} />
          </div>
        </div>
      </div>
      <div className="footer">
        <button type="button" className="btn btn-primary" onClick={validateLogin}>
          Login
          </button>
          <span style={{display: 'block'}}><i>No account? <a href="/#" onClick={registerPage}>Create one!</a></i></span>
      </div>    
    </div>
  );
};

export default Login;