import { ComponentType, useState, useEffect } from 'react';
import {
  lighten, Icon, styled, Grid, IconButton, Button,
  Divider, CardContent, CardHeader
} from '@mui/material';
import { DivBox } from '../../StyledComponents/styled';
import TextField from '@mui/material/TextField';
import GridViewReport from '../../components/GridViewReport/index';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select'
import { StatementOfSales, StatementOfSummary, GetItemHistoryDetails, StatementOfStock } from "../../services/reports-service";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GetCustomerData } from "../../services/customer-service";
import { Toaster } from 'react-hot-toast';

export const ReceiptReport = () => {

  const [reportsType, setReportsType] = useState('Statement Of Sales');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [pk_CustomerId, setPk_CustomerId] = useState(0);
  const [customerlist, setCustomerlist] = useState([]);

  useEffect(() => {
    const loadCustomers = async () => {
      const itemsdata = await GetCustomerData('');
      setCustomerlist(itemsdata)
    }
    loadCustomers();
  }, []);

  const handleReportsTypeChange = (event) => {
    setReportsType(event.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(new Date(event));
  };

  const handleEndDateChange = (event) => {
    setEndDate(new Date(event));
  };

  const handleCustomerSelect = (event) => {
    setPk_CustomerId(event.value);
  };

  const handleGenerate = async () => {
    
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <DivBox>
            <CardHeader
              title="Reports Section"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <FormControl fullWidth style={{ margin: '8px' }}>
                      <Select
                        placeholder={<div>Type to search customer</div>}
                        options={customerlist}
                        value={customerlist.filter(function (option) {
                          return option.value === pk_CustomerId;
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        onChange={(event) => handleCustomerSelect(event)} />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <DatePicker
                      label="Start Date"
                      inputFormat="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <DatePicker
                      label="End Date"
                      inputFormat="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant="contained" style={{ marginTop: '15px' }} onClick={handleGenerate}>Generate</Button>
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </CardContent>
          </DivBox>
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;