import { Drawer as MuiDrawer, styled } from '@mui/material';
import { Routes } from './Routes';

import { DRAWER_WIDTH } from '../../utils/constants';
import { navClosedMixin, navOpenedMixin } from '../../styles/mixins';

interface NavigationProps {
  open: boolean | undefined;
  handleClose: () => void;
}

export const Navigation = ({ open, handleClose }: NavigationProps) => {
  return (
    <>
        {open && <NavBarOpen>
          <Routes menuOpen={open} />
        </NavBarOpen>}
        {!open && <NavBarClose >
        </NavBarClose>}
    </>
  );
};

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const NavBarOpen = styled('div')`
    background-color: white;
    margin-top: 75px;
    margin-bottom: 25px;
    border-radius: 0 30px 30px 0; 
    width: 250px;
`;

const NavBarClose = styled('div')`
    background-color: white;
    margin-top: 75px;
    margin-bottom: 25px;
    border-radius: 0 30px 30px 0; 
    width: 20px;
`;