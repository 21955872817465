import { FC, useState, useEffect } from 'react';
import { styled, Box } from '@mui/material';
import { useHistory } from "react-router-dom";
import { Navigation } from '../Navigation';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useSelector } from 'react-redux';
import { FOOTER_HEIGHT } from '../../utils/constants';
import "./layout.css";

export const Layout: FC = ({ children }) => {
  const [open, setOpen] = useState(window.innerWidth <= 768 ? false : true);
  const toggleNavigation = () => setOpen((status) => !status);
  const username = useSelector(state => state["users"].email)
  let history = useHistory();

  useEffect(() => {
    const onInit = () => {
      if (username == undefined || username == '')
        history.push("/");
    }
    onInit();
  }, []);

  return (
    <LayoutWrapper>
      <ContentWrapper>
        <Box component="header">
          <Header toggleNavigation={toggleNavigation} />
        </Box>
        <Navigation open={open} handleClose={toggleNavigation} />
        <Box component="main" sx={{ flexGrow: 1, paddingLeft: '25px' }}>
          {children}
        </Box>
      </ContentWrapper>
      <Box component="footer">
        <Footer />
      </Box>
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled('div')`
  min-height: 100vh;
`;

const ContentWrapper = styled('div')`
  display: flex;
  min-height: calc(100vh - ${FOOTER_HEIGHT}px);
`;

const DrawerHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
