import { http_authenticate } from '../utils/common-http';
import { login } from '../reducers/usersreducer';

export const ValidateLogin = async (username: string, password: string, dispatch: any) => {

    const response = await http_authenticate(`api/Users/authenticate`, {
        Username: username,
        Password: password
    }
    );

    if (response != null && response) {
        let data = response.data;
        localStorage.setItem("access_token", data.result.token);
        dispatch(login(data.result));
        if (data.succeeded == true)
            return true;
    }
    return false;
}

export const RegisterLogin = async (username: string, firstname: string, password: string, email: string, dispatch: any) => {

    const response = await http_authenticate(`api/Users/register`, {
        Username: username,
        Firstname: firstname,
        Email: email,
        Password: password
    }
    );

    if (response != null && response) {
        let data = response.data;
        return data;
    }
    else
        return null;
}