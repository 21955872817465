import { ListItemButton, ListItemIcon, ListItemText, IconButton, styled } from '@mui/material';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { StyledListItemButton } from '../../../../StyledComponents/styled'
import { useHistory } from "react-router-dom";

export const SignOutRoute = () => {  
  let history = useHistory();

  const handleSignOutClick = () => {
    history.push("/");    
  };

  return (
    <StyledListItemButton onClick={handleSignOutClick}>
      <ListItemIcon>
        <IconButton size="small">
          <ExitToApp />
        </IconButton>
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </StyledListItemButton>
  );
};