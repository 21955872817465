import { http_post_method, http_get_method } from '../utils/common-http'; 
import { Customer as CustomerType } from '../types/Customer';

export const SaveCustomerData = async (CustomerData: CustomerType) => {
    const response = await http_post_method(`api/Customer`, CustomerData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetCustomerData = async (customer_code: string) => {
    const response = await http_get_method(`api/Customer`, { customerCode: customer_code });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const DeleteCustomer = async (pk_CustomerId: number) => {
    const response = await http_get_method(`api/Customer/DeleteCustomer`, { pk_CustomerId: pk_CustomerId });
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}