import { createSlice } from '@reduxjs/toolkit'

export const userReducers = createSlice({
  name: 'users',
  initialState: {
    email: "",
    firstname: "",
    lastName: "",
    mobileNo: "",
    token: "",
    username: ""
  },
  reducers: {
    login: (state, action) => {
      state.email = action.payload.email;
      state.firstname = action.payload.firstname;
      state.lastName = action.payload.lastName;
      state.mobileNo = action.payload.mobileNo;
      state.token = action.payload.token;
      state.username = action.payload.username;
    }
  },
})

// Action creators are generated for each case reducer function
export const { login } = userReducers.actions

export default userReducers.reducer