import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from './constants';
import { useSelector } from "react-redux";
import { saveAs } from 'filesaver';

export const get_financial_year = () => {
  let fiscalyear = "";
  let today = new Date();
  if ((today.getMonth() + 1) <= 3) {
    fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
  } else {
    fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
  }
  return fiscalyear
}