import React from 'react';

import { ComponentType, useState, useEffect } from 'react';
import {
  lighten, Icon, styled, Grid, IconButton, ListItemIcon,
  Divider, Container, Typography, CardHeader, CardContent
} from '@mui/material';
import { DivBox, InfoBox } from '../../StyledComponents/styled';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import GridView from '../../components/GridView/index';
import Insights from '../../components/Insights/index';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { WorkOrder as WorkOrderType } from '../../types/WorkOrder';
import { SaveWorkOrderData, GetWorkOrderList, GetWorkOrderData, DeleteWorkOrder } from "../../services/work-order-service";
import { GetItemData } from "../../services/item-service";
import { GetCustomerData } from "../../services/customer-service";
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";

export const WorkOrder = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [customerlist, setCustomerlist] = useState([]);
  const [itemlist, setItemlist] = useState([]);
  const [workOrderList, setWorkOrderList] = useState([]);
  const [selectedMode, setSelectedMode] = useState<String>('History');
  const [WorkOrderData, setWorkOrderData] = useState<WorkOrderType>({
    pk_WorkOrderId: 0,
    work_order_code: '',
    work_order_type: 'Sales',
    WONo: '',
    WODate: new Date(),
    pk_CustomerId: 0,
    DCNo: '',
    DCDate: new Date(),
    CustomerAddr: '',
    PONo: '',
    PODate: new Date(),
    RGBNo: '',
    RGBDate: new Date(),
    F4No: '',
    F4Date: new Date(),
    WorkOrderItems: [{
      pk_itemId: 0,
      ItemRate: 0,
      Quantity: 0,
      WorkOrderDesc: ''
    }]
  });

  const work_order_types = [
    { value: 'Rework', label: 'Rework' },
    { value: 'Sales', label: 'Sales' }
  ];

  const loadWorkOrders = async () => {
    const workOrdersList = await GetWorkOrderList(0);

    let woCnt = 0;
    if (workOrdersList.length > 0) {
      const wono = workOrdersList[0].label;
      woCnt = parseInt(wono.substring(2, wono.length));
    }
    const incNo  = (100001 + woCnt).toString();

    const WONo = "WO" + incNo.substring(1, incNo.length);
    WorkOrderData.WONo = WONo;
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
    setWorkOrderList(workOrdersList)
  }

  const loadItems = async (pk_ItemId: number) => {
    const itemsdata = await GetItemData(pk_ItemId);
    setItemlist(itemsdata.Table)
  }

  useEffect(() => {
    const loadCustomers = async () => {
      const itemsdata = await GetCustomerData('');
      setCustomerlist(itemsdata)
    }
    loadItems(0);
    loadCustomers();
    loadWorkOrders();
  }, []);

  const handleInfoModeChange = (mode: String) => {
    setSelectedMode(mode);
  }

  const handleWONoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.WONo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleWODateChange = (newValue) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.WODate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleDCNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.DCNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleDCDateChange = (newValue) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.DCDate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handlePONoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.PONo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.CustomerAddr = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handlePODateChange = (newValue) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.PODate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleRGBNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.RGBNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleRGBDateChange = (newValue) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.RGBDate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleF4NoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.F4No = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleF4DateChange = (newValue) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.F4Date = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  }

  const handleCustomerSelect = (event) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.pk_CustomerId = event.value;
    }    
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  };

  const handleWorkOrderTypeChange = (event) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.work_order_type = event.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  };

  const handleSaveClick = async () => {
    const filterWOItemWithQty = WorkOrderData.WorkOrderItems.filter(function (el) {
      return el.Quantity > 0
    });
    if (filterWOItemWithQty.length > 0) {
      let data = await SaveWorkOrderData(WorkOrderData);
      if (data != undefined && data.succeeded) {
        toast.success('Successfully created Work order!');
        loadWorkOrders();
        setWorkOrderData({
          pk_WorkOrderId: 0,
          work_order_code: '',
          work_order_type: 'Rework',
          WONo: '',
          WODate: new Date(),
          pk_CustomerId: 0,
          DCNo: '',
          DCDate: new Date(),
          CustomerAddr: '',
          PONo: '',
          PODate: new Date(),
          RGBNo: '',
          RGBDate: new Date(),
          F4No: '',
          F4Date: new Date(),
          WorkOrderItems: [{
            pk_itemId: 0,
            ItemRate: 0,
            Quantity: 0,
            WorkOrderDesc: ''
          }]
        });
      }
      else {
        toast.error('Error in creating Work order please try again!');
      }
    }
    else {
      toast.error('Please add some items before saving!');
    }
  }

  const handleItemSelect = (event, key) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.WorkOrderItems[key].pk_itemId = event.value;
    }
    WorkOrderData.WorkOrderItems[key].ItemRate = event.item_rate;
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  };
  
  const handleQuantityChange = (event, key) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.WorkOrderItems[key].Quantity = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  };

  const handleItemRateChange = (event: React.ChangeEvent<HTMLInputElement>, key: any) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.WorkOrderItems[key].ItemRate = parseFloat(event.currentTarget.value);
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  };

  const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>, key: any) => {
    if (WorkOrderData != undefined) {
      WorkOrderData.WorkOrderItems[key].WorkOrderDesc = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    setWorkOrderData(newData);
  };

  const handleDeleteRow = (key) => {
    let newData = JSON.parse(JSON.stringify(WorkOrderData));
    newData.WorkOrderItems.splice(key, 1);
    setWorkOrderData(newData);
  }

  const handleAddRow = () => {
    const newData = JSON.parse(JSON.stringify(WorkOrderData));
    newData.WorkOrderItems.push({
      pk_itemId: 0,
      ItemRate: 0,
      Quantity: 0,
      WorkOrderDesc: ''
    })
    setWorkOrderData(newData);
  }

  const handleClearAll = () => {
    toast.success('Work Order Data Cleared!');
    setWorkOrderData({
      pk_WorkOrderId: 0,
      work_order_code: '',
      work_order_type: 'Rework',
      WONo: '',
      WODate: new Date(),
      pk_CustomerId: 0,
      DCNo: '',
      DCDate: new Date(),
      CustomerAddr: '',
      PONo: '',
      PODate: new Date(),
      RGBNo: '',
      RGBDate: new Date(),
      F4No: '',
      F4Date: new Date(),
      WorkOrderItems: [{
        pk_itemId: 0,
        ItemRate: 0,
        Quantity: 0,
        WorkOrderDesc: ''
      }]
    });
  }

  const handleHistoryOnclick = async (id) => {
    const itemsdata = await GetWorkOrderData(id);
    
    if (itemsdata.length > 0) {

      const workOrderItems = [];
      for (let i = 0; i < itemsdata.length; i++) {
        workOrderItems.push(
          {
            pk_itemId: itemsdata[i].pk_ItemID,
            ItemRate: itemsdata[i].ItemRate,
            Quantity: itemsdata[i].Quantity.toString(),
            WorkOrderDesc: itemsdata[i].Desc
          }
        )
      }

      setWorkOrderData({
        pk_WorkOrderId: itemsdata[0].pk_workorderid,
        work_order_code: itemsdata[0].WONo,
        work_order_type: itemsdata[0].WorkOrderType,
        WONo: itemsdata[0].WONo,
        WODate: new Date(),
        pk_CustomerId: itemsdata[0].pk_customerid,
        DCNo: itemsdata[0].DCNo,
        DCDate: new Date(),
        CustomerAddr: itemsdata[0].CustomerAddr,
        PONo: itemsdata[0].PONo,
        PODate: new Date(),
        RGBNo: itemsdata[0].RGBNo,
        RGBDate: new Date(),
        F4No: itemsdata[0].F4No,
        F4Date: new Date(),
        WorkOrderItems: workOrderItems
      });
    }
    else {
      setWorkOrderData({
        pk_WorkOrderId: 0,
        work_order_code: '',
        work_order_type: 'Rework',
        WONo: '',
        WODate: new Date(),
        pk_CustomerId: 0,
        DCNo: '',
        DCDate: new Date(),
        CustomerAddr: '',
        PONo: '',
        PODate: new Date(),
        RGBNo: '',
        RGBDate: new Date(),
        F4No: '',
        F4Date: new Date(),
        WorkOrderItems: [{
          pk_itemId: 0,
          ItemRate: 0,
          Quantity: 0,
          WorkOrderDesc: ''
        }]
      });
    }
  }

  const handleDeleteWorkOrderOnclick = async (id) => {
    const data = await DeleteWorkOrder(id);
    if (data != undefined && data.succeeded && data.result.message == 'Deleted Successfully.!') {
      toast.success('Work Order Deleted!');
      loadWorkOrders();
    }
    else {
      toast.error(data.result.message);
    }
  }

  const columns = [
  {
      field: 'label',
      headerName: 'Work Order No',
      width: 150,
      renderCell: (params) => (
        <button onClick={() => handleHistoryOnclick(params.row.value)} className="btn btn-link">
          {params.row.label}
        </button>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDeleteWorkOrderOnclick(params.row.value)} className="btn btn-link">
            Delete Work Order
          </button>
        </>
      )
    }
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DivBox>
            <CardHeader
              action={
                <ListItemIcon>
                  <IconButton size="large">
                    <SaveIcon onClick={handleSubmit(handleSaveClick)} />
                  </IconButton>
                  <IconButton size="large">
                    <ClearAllIcon onClick={handleClearAll} />
                  </IconButton>
                </ListItemIcon>
              }
              title="Work Order Entry"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Container>                  
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        disabled
                        id="outlined-required"
                        label="Work Order No."
                        value={WorkOrderData?.WONo}
                        onChange={handleWONoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Work Order Date"
                        disabled
                        inputFormat="DD/MM/YYYY"
                        value={WorkOrderData?.WODate}
                        onChange={handleWODateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          placeholder={<div>Please select Work Order Type</div>}
                          isDisabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                          options={work_order_types}
                          value={work_order_types.filter(function (option) {
                            return option.value === WorkOrderData.work_order_type;
                          })}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={(event) => handleWorkOrderTypeChange(event)} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          placeholder={<div>Type to search customer</div>}
                          isDisabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                          options={customerlist}
                          value={customerlist.filter(function (option) {
                            return option.value === WorkOrderData.pk_CustomerId;
                          })}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={(event) => handleCustomerSelect(event)} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        id="outlined-required"
                        label="D.C. No."
                        value={WorkOrderData?.DCNo}
                        onChange={handleDCNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="D.C. Date"
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        inputFormat="DD/MM/YYYY"
                        value={WorkOrderData?.DCDate}
                        onChange={handleDCDateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Typography gutterBottom variant="h5" component="div">
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        id="outlined-required"
                        label="Address"
                        value={WorkOrderData?.CustomerAddr}
                        onChange={handleAddressChange}
                        multiline
                        rows={1}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        id="outlined-required"
                        label="Purchase Order Number"
                        value={WorkOrderData?.PONo}
                        onChange={handlePONoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="P.O. Date"
                        inputFormat="DD/MM/YYYY"
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        value={WorkOrderData?.PODate}
                        onChange={handlePODateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>                    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        id="outlined-required"
                        label="RGP No."
                        value={WorkOrderData?.RGBNo}
                        onChange={handleRGBNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="RGP Date"
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        inputFormat="DD/MM/YYYY"
                        value={WorkOrderData?.RGBDate}
                        onChange={handleRGBDateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-required"
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        label="57F4 No."
                        value={WorkOrderData?.F4No}
                        onChange={handleF4NoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="57F4 Date"
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                        inputFormat="DD/MM/YYYY"
                        value={WorkOrderData?.F4Date}
                        onChange={handleF4DateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <table className={"table"}>
                      <thead>
                        <tr>
                          <th style={{width: '10%'}}>S.No</th>
                          <th style={{width: '30%'}}>Item</th>
                          <th style={{width: '10%'}}>Rate</th>
                          <th style={{width: '10%'}}>Quantity</th>
                          <th style={{width: '30%'}}>Description</th>
                          <th style={{width: '10%'}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {WorkOrderData?.WorkOrderItems.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>
                                {itemlist != undefined && itemlist.length > 0 &&
                                  <Select
                                    options={itemlist}
                                    isDisabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                                    value={itemlist.filter(function (option) {
                                      return option.value === val.pk_itemId;
                                    })}
                                    onChange={(event) => handleItemSelect(event, key)}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 999999 }) }} />
                                }
                              </td>
                              <td><input type="text" className="form-control"
                                disabled={WorkOrderData?.pk_WorkOrderId != 0 ? true : false}
                                value={val.ItemRate} onChange={(event) => handleItemRateChange(event, key)} /></td>
                              <td><input type="text" className="form-control" value={val.Quantity} onChange={(event) => handleQuantityChange(event, key)} /></td>
                              <td><input type="text" className="form-control" value={val.WorkOrderDesc} onChange={(event) => handleDescChange(event, key)} /></td>
                              <td><input type="button" className="btn btn-danger" value="Delete" onClick={() => handleDeleteRow(key)} /></td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={5}><input type="button" className="btn btn-danger" value="Add New Row" onClick={handleAddRow} 
                        disabled = {WorkOrderData?.pk_WorkOrderId != 0 ? true : false} /></td>
                        </tr>
                      </tfoot>
                    </table>
                  </Grid>
                </Container>
              </LocalizationProvider>
            </CardContent>
          </DivBox>
        </Grid>
        <Grid item xs={12} md={2}>
          <InfoBox>
            <ListItemIcon style={{ margin: '15px', minWidth: '35px' }}>
              <StyledIconButton size="small" isSelected={selectedMode == 'History' ? true : false} onClick={() => handleInfoModeChange("History")}>
                <StyledIcon component={ManageSearchIcon} isSelected={selectedMode == 'History' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon>
              <StyledIconButton size="small" isSelected={selectedMode == 'Insights' ? true : false} onClick={() => handleInfoModeChange("Insights")}>
                <StyledIcon component={InsightsIcon} isSelected={selectedMode == 'Insights' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon style={{ float: 'right', marginTop: '8px' }}>
              <IconButton size="large">
                <AspectRatioIcon />
              </IconButton>
            </ListItemIcon>
            <Divider />
            {selectedMode == 'History' ? <GridView columns={columns} rows={workOrderList} />  : <Insights />}
          </InfoBox>
        </Grid>
      </Grid>      
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;