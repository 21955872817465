import { lighten, Icon, styled, Grid, IconButton, ListItemIcon, Divider, Container, Box } from '@mui/material';
import { useContext, ComponentType, useState } from 'react';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';


export const GridView = (props: any) => {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={props.rows == undefined ? [] : props.rows}
              columns={props.columns == undefined ? [] : props.columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick 
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GridView;