import {
  Home as HomeIcon,
  SettingsOutlined as SettingsIcon,
  Storage, People,RecordVoiceOver, Category
} from '@mui/icons-material';

import { Customer } from '../pages/Customer/Customer';
import { CustomerItemMap } from '../pages/CustomerItemMap/Index';
import { SupplierItemMap } from '../pages/SupplierItemMap/Index';

import { WorkOrder } from '../pages/WorkOrder/WorkOrder';
import { InspectionReport } from '../pages/InspectionReport/Index';
import { DeliveryChallan } from '../pages/DeliveryChallan/DeliveryChallan';
import { Invoice } from '../pages/Invoice/Invoice';
import { Supplier } from '../pages/Supplier/Index';
import { Item } from '../pages/Item/Index';
import { Reports } from '../pages/Reports/Index';
import { ReceiptReport } from '../pages/ReceiptReport/Index';
import { PaymentReceipt } from '../pages/PaymentReceipt/Index';

import { Route } from '../types/Route';

// const routes: Array<Route> = [
//   {
//     key: 'router-home',
//     title: 'Home',
//     description: 'Home',
//     component: Home,
//     path: '/home',
//     isEnabled: true,
//     icon: HomeIcon,
//     appendDivider: true,
//   },
//   {
//     key: 'router-dashboard',
//     title: 'Dashboard',
//     description: 'Dashboard',
//     path: '/dashboard',
//     isEnabled: true,
//     icon: DashboardIcon,
//   },
//   {
//     key: 'router-gh',
//     title: 'GitHub',
//     description: 'GitHub',
//     isEnabled: true,
//     icon: GitHubIcon,
//     subRoutes: [
//       {
//         key: 'router-gh-public',
//         title: 'Public Repos',
//         description: 'Public Repos',
//         path: '/gh/public',
//         isEnabled: true,
//         icon: PublicIcon,
//         subRoutes: [
//           {
//             key: 'router-gh-public',
//             title: 'Public Repos',
//             description: 'Public Repos',
//             path: '/gh/public',
//             isEnabled: true,
//             icon: PublicIcon,
//           },
//           {
//             key: 'router-gh-private',
//             title: 'Private Repos',
//             description: 'Private Repos',
//             path: '/gh/private',
//             isEnabled: false,
//             icon: PrivateIcon,
//           },
//         ],
//       },
//       {
//         key: 'router-gh-private',
//         title: 'Private Repos',
//         description: 'Private Repos',
//         path: '/gh/private',
//         isEnabled: false,
//         icon: PrivateIcon,
//       },
//     ],
//   },
//   {
//     key: 'router-code',
//     title: 'Code Editor',
//     description: 'Code Editor',
//     path: '/code-editor',
//     isEnabled: true,
//     icon: CodeIcon,
//     appendDivider: true,
//   },
//   {
//     key: 'router-my-account',
//     title: 'My Account',
//     description: 'My Account',
//     path: '/account',
//     isEnabled: true,
//     icon: UserIcon,
//     subRoutes: [
//       {
//         key: 'router-settings',
//         title: 'Settings',
//         description: 'Account Settings',
//         path: '/account/settings',
//         isEnabled: true,
//         icon: SettingsIcon,
//       },
//       {
//         key: 'router-preferences',
//         title: 'Preferences',
//         description: 'Account Preferences',
//         path: '/account/preferences',
//         isEnabled: true,
//         icon: ListIcon,
//       },
//       {
//         key: 'router-billing',
//         title: 'Billing',
//         description: 'Account Billing',
//         path: '/account/billing',
//         isEnabled: true,
//         icon: BillingIcon,
//       },
//     ],
//   },
// ];

const routes: Array<Route> = [
  {
    key: 'master',
    title: 'Master',
    description: 'Master',
    isEnabled: true,
    expanded: true,
    icon: Storage,
    appendDivider: true,
    subRoutes: [
      {
        key: 'customer-settings',
        title: 'Customer',
        description: 'Customer Creation',
        path: '/master/customer',
        isEnabled: true,
        icon: People,
        component: Customer,
      },
      {
        key: 'supplier-settings',
        title: 'Supplier',
        description: 'Supplier Creation',
        path: '/master/supplier',
        isEnabled: true,
        icon: RecordVoiceOver,
        component: Supplier,
      },
      {
        key: 'item-settings',
        title: 'Item',
        description: 'Item Creation',
        path: '/master/item',
        isEnabled: true,
        icon: Category,
        component: Item,
      },
      {
        key: 'customer-item-mapping',
        title: 'Customer Item Mapping',
        description: 'Customer Item Mapping',
        path: '/master/customeritemmap',
        isEnabled: true,
        icon: People,
        component: CustomerItemMap,
      },
      {
        key: 'supplier-item-mapping',
        title: 'Supplier Item Mapping',
        description: 'Supplier Item Mapping',
        path: '/master/supplieritemmap',
        isEnabled: true,
        icon: RecordVoiceOver,
        component: SupplierItemMap,
      },
    ]
  },
  {
    key: 'transaction',
    title: 'Transaction',
    description: 'Transaction',
    isEnabled: true,
    expanded: true,
    icon: Storage,
    appendDivider: true,
    subRoutes: [
      {
        key: 'work-order',
        title: 'Work Order',
        description: 'Work Order',
        path: '/master/workorder',
        isEnabled: true,
        icon: People,
        component: WorkOrder
      },
      {
        key: 'delivery-challan',
        title: 'Delivery Challan',
        description: 'Delivery Challan',
        path: '/master/deliverychallan',
        isEnabled: true,
        icon: People,
        component: DeliveryChallan
      },
      {
        key: 'Invoice',
        title: 'Invoice',
        description: 'Invoice',
        path: '/master/Invoice',
        isEnabled: true,
        icon: People,
        component: Invoice
      },      
      {
        key: 'inspection-report',
        title: 'Inspection Report',
        description: 'Inspection Report',
        path: '/master/inspectionReport',
        isEnabled: true,
        icon: People,
        component: InspectionReport
      },      
      {
        key: 'payment',
        title: 'Payment Receipt',
        description: 'Payment Receipt',
        path: '/master/payment',
        isEnabled: true,
        icon: People,
        component: PaymentReceipt
      },      
      {
        key: 'reports',
        title: 'Reports',
        description: 'Reports',
        path: '/master/reports',
        isEnabled: true,
        icon: People,
        component: Reports
      },      
      {
        key: 'ledger',
        title: 'Ledger',
        description: 'Ledger',
        path: '/master/ledger',
        isEnabled: true,
        icon: People,
        component: ReceiptReport
      }      
    ]
  }
];


export default routes;
