import { styled } from '@mui/material';
import { ListItemButton } from '@mui/material';

export const DivBox = styled('div')`
  background-color: white;
  border-radius: 30px; 
  margin-top: 75px;
  height: 83vh;
  margin-right: 10px;
`;

export const InfoBox = styled('div')`
  background-color: white;
  border-radius: 30px 0 0 30px; 
  margin-top: 75px;
  height: 83vh
`;


export const StyledListItemButton = styled(ListItemButton)`
  width: 100%;
`;
