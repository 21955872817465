import { http_post_method, http_get_method } from '../utils/common-http'; 
import { Supplier as SupplierType } from '../types/Supplier';

export const SaveSupplierData = async (SupplierData: SupplierType) => {
    const response = await http_post_method(`api/Supplier`, SupplierData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetSupplierData = async (supplier_code: string) => {
    const response = await http_get_method(`api/Supplier`, { supplierCode: supplier_code });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}