import { lighten, Icon, styled, Grid, IconButton, ListItemIcon, Divider, Container } from '@mui/material';
import { useContext, ComponentType, useState } from 'react';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';

export const Insights = () => {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          
        </Grid>
      </Grid>
    </>
  );
};

export default Insights;