import React from 'react';

import { ComponentType, useState, useEffect } from 'react';
import {
  lighten, Icon, styled, Grid, IconButton, ListItemIcon,
  Divider, Container, Card, CardActionArea, CardContent, Typography, Box, CardHeader
} from '@mui/material';
import { DivBox, InfoBox } from '../../StyledComponents/styled';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import GridView from '../../components/GridView/index';
import Insights from '../../components/Insights/index';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select'
import { Receipt as ReceiptType } from '../../types/Receipt';
import { get_financial_year } from '../../utils/common';
import { SaveReceiptData, DeleteReceipt, GetInvoiceDataForReceipt, GetInvoiceListForCustomer, GetReceiptData } from "../../services/receipt-service";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GetItemData } from "../../services/item-service";
import { GetCustomerData } from "../../services/customer-service";
import { GetWorkOrderList } from "../../services/work-order-service";
import dayjs, { Dayjs } from 'dayjs';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";

export const PaymentReceipt = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [customerlist, setCustomerlist] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [receiptList, setReceiptList] = useState([]);
  const [selectedMode, setSelectedMode] = useState<String>('History');
  const [ReceiptData, setReceiptData] = useState<ReceiptType>({
    pk_paymentreceiptid: 0,
    ReceiptNo: '',
    ReceiptDate: new Date(),
    pk_customerid: 0,
    TotalAmountPaid: 0,
    TransactionType: '',
    TransactionNo: '',
    ReferenceNo: '',
    ReceiptItems: [{
      pk_paymentreceiptid: 0,
      pk_InvoiceId: 0,
      PaymentType: '',
      BalanceAmount: 0,
      TDSAmount: 0,
      PaidAmount: 0
    }],
  });
  const PaymentTypeList = [
    { value: 'HalfPayment', label: 'Half Payment' },
    { value: 'FullPayment', label: 'Full Payment' }
  ];  

  const loadReceipt = async () => {
    const receiptsList = await GetReceiptData(0);
    const finYear = get_financial_year();
    const receiptsListForFY = receiptsList.filter(function (row) {
      return row.label.endsWith(finYear);
    });

    let invCnt = 0;

    if (receiptsListForFY.length > 0) {
      const receiptno = receiptsListForFY.reduce(function (prev, current) {
        return (prev.id > current.id) ? prev : current
      }).label;
      invCnt = parseInt(receiptno.substring(4,receiptno.indexOf('/')));
    }

    const incNo = (100001 + invCnt).toString();
    const WONo = 'RCP-' + incNo.substring(1, incNo.length) + '/' + finYear;
    ReceiptData.ReceiptNo = WONo;
    const newData = JSON.parse(JSON.stringify(ReceiptData));    
    setReceiptData((prevData) => ( newData ));
    setReceiptList(prevData => (
      receiptsList
    ));
  }

  useEffect(() => {
    const loadCustomers = async () => {
      const itemsdata = await GetCustomerData('');
      setCustomerlist(itemsdata)
    }
    loadCustomers();
    loadReceipt();
  }, []);

  const handleInfoModeChange = (mode: String) => {
    setSelectedMode(mode);
  }

  const handleCustomerSelect = async (event) => {
    if (ReceiptData != undefined) {
      ReceiptData.pk_customerid = event.value;
    }
    
    const invoiceData = await GetInvoiceListForCustomer(event.value);
    setInvoiceList(invoiceData);
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
  };

  const handleInvoiceNoChange = async (event) => {
    const ReceiptItems = [];
    for (let i = 0; i < event.length; i++) {
      const invoiceData = await GetInvoiceDataForReceipt(event[i].id);
      for (let i = 0; i < invoiceData.length; i++) {
        const tdsAmt = invoiceData[i].TDSAmount ?? parseFloat(((invoiceData[i].NetValue * 0.02) + (invoiceData[i].NetValue * 0.0002)).toFixed(2));
        const balanceAmt = parseFloat((invoiceData[i].NetValue - tdsAmt - invoiceData[i].ExistingPaidAmount).toFixed(2));
        ReceiptItems.push(
          {
            pk_paymentreceiptid: 0,
            pk_InvoiceId: invoiceData[i].pk_invoiceid,
            PaymentType: '',
            BalanceAmount: balanceAmt,
            TDSAmount: tdsAmt,
            PaidAmount: 0
          }
        )
      }
      ReceiptData.ReceiptItems = ReceiptItems;

    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
    setReceiptData((prevState) => (newData));
  }

  const handleReceiptDateChange = (newValue) => {
    if (ReceiptData != undefined) {
      ReceiptData.ReceiptDate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
  }

  const handleReceiptNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ReceiptData != undefined) {
      ReceiptData.ReceiptNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
  }

  const handleTotalAmountPaidChange = (event) => {
    if (ReceiptData != undefined) {
      ReceiptData.TotalAmountPaid = parseFloat(event.currentTarget.value);
    }

    let TotalAmountPaid = ReceiptData.TotalAmountPaid;
    for (let i = 0; i < ReceiptData.ReceiptItems.length; i++) {
      if (TotalAmountPaid > ReceiptData.ReceiptItems[i].BalanceAmount) {
        ReceiptData.ReceiptItems[i].PaidAmount = ReceiptData.ReceiptItems[i].BalanceAmount;
        TotalAmountPaid = TotalAmountPaid - ReceiptData.ReceiptItems[i].BalanceAmount;
        ReceiptData.ReceiptItems[i].PaymentType = "FullPayment";
      }
      else {
        ReceiptData.ReceiptItems[i].PaidAmount = parseFloat(TotalAmountPaid.toFixed(2)) ;
        ReceiptData.ReceiptItems[i].PaymentType = "HalfPayment";
        TotalAmountPaid = 0;
      }
    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));

    setReceiptData(newData);
  }

  const handleTransactionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ReceiptData != undefined) {
      ReceiptData.TransactionType = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
  }

  const handleTransactionNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ReceiptData != undefined) {
      ReceiptData.TransactionNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
  }

  const handleReferenceNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ReceiptData != undefined) {
      ReceiptData.ReferenceNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
  }

  const handlePaidAmountChange = (event, key) => {
    if (ReceiptData != undefined) {
      ReceiptData.ReceiptItems[key].PaidAmount = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
  };

  const handlePaymentTypeSelect = (event, key) => {
    if (ReceiptData != undefined) {
      ReceiptData.ReceiptItems[key].PaymentType = event.value;
    }
    const newData = JSON.parse(JSON.stringify(ReceiptData));
    setReceiptData(newData);
  };

  const handleSaveClick = async () => {
    const filterReceiptItemWithDesQty = ReceiptData.ReceiptItems.filter(function (el) {
      return el.PaidAmount > 0
    });
    if (filterReceiptItemWithDesQty.length > 0) {
      const data = await SaveReceiptData(ReceiptData);
      if (data != undefined && data.succeeded) {
        toast.success('Receipt Saved Successfully!');
        setReceiptData(prevData => {
          return {
            pk_paymentreceiptid: 0,
            ReceiptNo: '',
            ReceiptDate: new Date(),
            pk_customerid: 0,
            TotalAmountPaid: 0,
            TransactionType: '',
            TransactionNo: '',
            ReferenceNo: '',
            ReceiptItems: [{
              pk_paymentreceiptid: 0,
              pk_InvoiceId: 0,
              PaymentType: '',
              BalanceAmount: 0,
              TDSAmount: 0,
              PaidAmount: 0
            }],
          }; // Update the state based on previous state if needed
        });
        loadReceipt();
      }
      else {
        toast.error('Error in creating Receipt please try again!');
      }
    }
    else {
      toast.error('Please add some items before saving!');
    }
  }

  const handleClearAll = () => {
    toast.success('Receipt Data Cleared!');
    setReceiptData({
      pk_paymentreceiptid: 0,
      ReceiptNo: '',
      ReceiptDate: new Date(),
      pk_customerid: 0,
      TotalAmountPaid: 0,
      TransactionType: '',
      TransactionNo: '',
      ReferenceNo: '',
      ReceiptItems: [{
        pk_paymentreceiptid: 0,
        pk_InvoiceId: 0,
        PaymentType: '',
        BalanceAmount: 0,
        TDSAmount: 0,
        PaidAmount: 0
      }],
    });
  }

  const handleDeleteReceiptOnclick = async (id) => {
    const data = await DeleteReceipt(id);
    if (data != undefined && data.succeeded) {
      toast.success('Receipt Deleted!');
      loadReceipt();
    }
  }

  const columns = [
  {
      field: 'rec-no',
      headerName: 'Receipt No',
      width: 150,
      renderCell: (params) => (
        <span>
          {params.row.label}
        </span>
      )
    }
  ];

  return (
    <>

      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DivBox>
            <CardHeader
              action={
                <ListItemIcon>
                  <IconButton size="large">
                    <SaveIcon onClick={handleSubmit(handleSaveClick)} />
                  </IconButton>
                  <IconButton size="large">
                    <ClearAllIcon onClick={handleClearAll} />
                  </IconButton>
                </ListItemIcon>
              }
              title="Payment Receipt"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          placeholder={<div>Type to search customer</div>}
                          options={customerlist}
                          value={customerlist.filter(function (option) {
                            return option.value === ReceiptData.pk_customerid;
                          })}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={(event) => handleCustomerSelect(event)} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          options={invoiceList}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={(event) => handleInvoiceNoChange(event)} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Receipt No."
                        value={ReceiptData?.ReceiptNo}
                        onChange={handleReceiptNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Receipt Date"
                        inputFormat="DD/MM/YYYY"
                        value={ReceiptData?.ReceiptDate}
                        onChange={handleReceiptDateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Total Amount Paid"
                          value={ReceiptData?.TotalAmountPaid}
                          onChange={handleTotalAmountPaidChange}
                          sx={{ m: 1, width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Transaction Type"
                        value={ReceiptData?.TransactionType}
                        onChange={handleTransactionTypeChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Transaction No"
                          value={ReceiptData?.TransactionNo}
                          onChange={handleTransactionNoChange}
                          sx={{ m: 1, width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Reference No"
                        value={ReceiptData?.ReferenceNo}
                        onChange={handleReferenceNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ overflowX: 'scroll' }}>
                    <table className={"table table-receipt"} style={{ width: '1000px' }}>
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>S.No</th>
                          <th style={{ width: "10%" }}>Inv No</th>
                          <th style={{ width: "15%" }}>To Be Paid</th>
                          <th style={{ width: "15%" }}>Deduction</th>
                          <th style={{ width: "15%" }}>Paid Amount</th>
                          <th style={{ width: "25%" }}>Payment Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ReceiptData?.ReceiptItems.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>
                                {val.pk_InvoiceId}
                              </td>
                              <td><input type="text" className="form-control" value={val.BalanceAmount} disabled /></td>
                              <td><input type="text" className="form-control" value={val.TDSAmount} disabled /></td>
                              <td><input type="text" className="form-control" value={val.PaidAmount} disabled /></td>
                              <td>
                                <Select
                                  options={PaymentTypeList}
                                  value={PaymentTypeList.filter(function (option) {
                                    return option.value === val.PaymentType;
                                  })}
                                  menuPortalTarget={document.body}
                                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                  onChange={(event) => handlePaymentTypeSelect(event, key)} />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </Container>
              </LocalizationProvider>
            </CardContent>
          </DivBox>
        </Grid>
        <Grid item xs={12} md={2}>
          <InfoBox>
            <ListItemIcon style={{ margin: '15px', minWidth: '35px' }}>
              <StyledIconButton size="small" isSelected={selectedMode == 'History' ? true : false} onClick={() => handleInfoModeChange("History")}>
                <StyledIcon component={ManageSearchIcon} isSelected={selectedMode == 'History' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon>
              <StyledIconButton size="small" isSelected={selectedMode == 'Insights' ? true : false} onClick={() => handleInfoModeChange("Insights")}>
                <StyledIcon component={InsightsIcon} isSelected={selectedMode == 'Insights' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon style={{ float: 'right', marginTop: '8px' }}>
              <IconButton size="large">
                <AspectRatioIcon />
              </IconButton>
            </ListItemIcon>
            <Divider />
            {selectedMode == 'History' ? <GridView columns={columns} rows={receiptList} /> : <Insights />}
          </InfoBox>
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;