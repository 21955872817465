import React from 'react';
import { ComponentType, useState, useEffect } from 'react';
import {
  lighten, Icon, styled, Grid, IconButton, ListItemIcon,
  Divider, Container, Card, CardActionArea, CardContent, Typography, CardHeader
} from '@mui/material';
import { DivBox, InfoBox } from '../../StyledComponents/styled';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import GridView from '../../components/GridView/index';
import Insights from '../../components/Insights/index';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Customer as CustomerType } from '../../types/Customer';
import { SaveCustomerData, GetCustomerData, DeleteCustomer } from "../../services/customer-service";
import { useSelector } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";

export const Customer = () => {
  const username = useSelector(state => state["users"].email);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [selectedMode, setSelectedMode] = useState<String>('History');
  const [customerlist, setCustomerlist] = useState([]);
  const [CustomerData, setCustomerData] = useState<CustomerType>({
    ID: 0,
    vendor_code: '',
    customer_code: '',
    customer_type: 'Regular',
    branch: '',
    name: '',
    GST: '',
    PanNo: '',
    office_address: '',
    office_city: '',
    office_pincode: '',
    office_state: '',
    office_country: '',
    office_phone: '',
    office_mobile: '',
    office_email: '',
    factory_address: '',
    factory_city: '',
    factory_pincode: '',
    factory_state: '',
    factory_country: '',
    factory_phone: '',
    factory_mobile: '',
    factory_email: '',
    CreatedBy: username,
    CreatedDate: '',
    UpdatedBy: '',
    UpdatedDate: ''
  });

  const loadCustomers = async () => {
    const itemsdata = await GetCustomerData('');
    setCustomerlist(itemsdata)
  }

  useEffect(() => {
    loadCustomers();
  }, []);

  const handleInfoModeChange = (mode: String) => {
    setSelectedMode(mode);
  }

  const handleVendorCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.vendor_code = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleGSTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.GST = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handlePanNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.PanNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleBranchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.branch = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleCustTypeChange = (event) => {
    if (CustomerData != undefined) {
      CustomerData.customer_type = event.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.name = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleOfficeAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.office_address = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleOffCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.office_city = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleOffPincodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.office_pincode = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleOffStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.office_state = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleOffCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.office_country = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleOffPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.office_phone = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleOffMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.office_mobile = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleOffEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.office_email = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleFactoryAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.factory_address = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleFactoryCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.factory_city = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleFactoryPincodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.factory_pincode = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleFactoryStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.factory_state = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleFactoryCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.factory_country = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleFactoryPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.factory_phone = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleFactoryMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.factory_mobile = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleFactoryEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (CustomerData != undefined) {
      CustomerData.factory_email = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(CustomerData));
    setCustomerData(newData);
  }

  const handleSaveClick = async () => {
    let data = await SaveCustomerData(CustomerData);
    if (data != undefined && data.succeeded) {
      toast.success('Successfully created customer!');
      loadCustomers();
      setCustomerData({
        ID: 0,        
        vendor_code: '',
        customer_code: '',
        customer_type: 'Regular',
        branch: '',
        name: '',
        GST: '',
        PanNo: '',
        office_address: '',
        office_city: '',
        office_pincode: '',
        office_state: '',
        office_country: '',
        office_phone: '',
        office_mobile: '',
        office_email: '',
        factory_address: '',
        factory_city: '',
        factory_pincode: '',
        factory_state: '',
        factory_country: '',
        factory_phone: '',
        factory_mobile: '',
        factory_email: '',
        CreatedBy: username,
        CreatedDate: '',
        UpdatedBy: '',
        UpdatedDate: ''
      });
    }
    else {
      toast.error('Error in creating Customer please try again!');
    }
  }

  const handleHistoryOnclick = async (id) => {
    const itemsdata = await GetCustomerData(id);
    setCustomerData(itemsdata[0]);
  }

  const handleClearAll = () => {
    toast.success('Customer Data Cleared!');
    setCustomerData({
      ID: 0,
      vendor_code: '',
      customer_code: '',
      customer_type: 'Regular',
      branch: '',
      name: '',
      GST: '',
      PanNo: '',
      office_address: '',
      office_city: '',
      office_pincode: '',
      office_state: '',
      office_country: '',
      office_phone: '',
      office_mobile: '',
      office_email: '',
      factory_address: '',
      factory_city: '',
      factory_pincode: '',
      factory_state: '',
      factory_country: '',
      factory_phone: '',
      factory_mobile: '',
      factory_email: '',
      CreatedBy: username,
      CreatedDate: '',
      UpdatedBy: '',
      UpdatedDate: ''
    });
  }

  const handleDeleteCustomerOnclick = async (id) => {
    if (confirm('Are you sure. You want to delete this Customer.?') == true) {
      const data = await DeleteCustomer(id);
      if (data != undefined && data.succeeded) {
        toast.success('Customer Deleted!');
        loadCustomers();
      }
    }
  }

  const columns = [
  {
      field: 'label',
      headerName: 'Name',
      width: 150,
      renderCell: (params) => (
        <button onClick={() => handleHistoryOnclick(params.row.value)} className="btn btn-link">
          {params.row.label}
        </button>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDeleteCustomerOnclick(params.row.value)} className="btn btn-link">
            Delete Customer
          </button>
        </>
      )
    }
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DivBox>
            <CardHeader
              action={
                <ListItemIcon>
                  <IconButton size="large">
                    <SaveIcon onClick={handleSubmit(handleSaveClick)} />
                  </IconButton>
                  <IconButton size="large">
                    <ClearAllIcon onClick={handleClearAll} />
                  </IconButton>
                </ListItemIcon>
              }
              title="Customer Master"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Vendor"
                      {...register("vendor_code", { required: true })}
                      value={(CustomerData?.vendor_code == null ? '' : CustomerData?.vendor_code)}
                      onChange={handleVendorCodeChange}
                      sx={{ m: 1, width: '100%' }}
                    />
                    {errors.vendor_code && errors.vendor_code.type === "required" && <span style={{ color: 'red', marginLeft: '10px' }}>This is required</span>}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="customer-type-label" style={{ margin: '8px' }}>Customer Type</InputLabel>
                      <Select
                        labelId="customer-type-label"
                        id="customer-type-select"
                        value={CustomerData?.customer_type}
                        label="Customer Type"
                        onChange={handleCustTypeChange}
                        style={{ margin: '8px', width: '100%' }}
                      >
                        <MenuItem value={"Regular"}>Regular</MenuItem>
                        <MenuItem value={"Temporary"}>Temporary</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Branch"
                      {...register("branch", { required: true })}
                      value={CustomerData?.branch}
                      onChange={handleBranchChange}
                      sx={{ m: 1, width: '100%' }}
                    />
                    {errors.branch && errors.branch.type === "required" && <span style={{ color: 'red', marginLeft: '10px' }}>This is required</span>}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Name"
                      {...register("name", { required: true })}
                      value={CustomerData?.name}
                      onChange={handleNameChange}
                      sx={{ m: 1, width: '100%' }}
                    />
                    {errors.name && errors.name.type === "required" && <span style={{ color: 'red', marginLeft: '10px' }}>This is required</span>}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="GST"
                      value={CustomerData?.GST}
                      onChange={handleGSTChange}
                      sx={{ m: 1, width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Pan"
                      value={CustomerData?.PanNo}
                      onChange={handlePanNoChange}
                      sx={{ m: 1, width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <Card style={{ marginTop: '25px' }}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Office
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Address"
                            value={CustomerData?.office_address}
                            onChange={handleOfficeAddressChange}
                            multiline
                            rows={4}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="City"
                            value={CustomerData?.office_city}
                            onChange={handleOffCityChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Pincode"
                            value={CustomerData?.office_pincode}
                            onChange={handleOffPincodeChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="State"
                            value={CustomerData?.office_state}
                            onChange={handleOffStateChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Country"
                            value={CustomerData?.office_country}
                            onChange={handleOffCountryChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Phone"
                            value={CustomerData?.office_phone}
                            onChange={handleOffPhoneChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Mobile"
                            value={CustomerData?.office_mobile}
                            onChange={handleOffMobileChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Email"
                            value={CustomerData?.office_email}
                            onChange={handleOffEmailChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card style={{ marginTop: '25px' }}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Factory
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Address"
                            value={CustomerData?.factory_address}
                            onChange={handleFactoryAddressChange}
                            multiline
                            rows={4}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="City"
                            value={CustomerData?.factory_city}
                            onChange={handleFactoryCityChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Pincode"
                            value={CustomerData?.factory_pincode}
                            onChange={handleFactoryPincodeChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="State"
                            value={CustomerData?.factory_state}
                            onChange={handleFactoryStateChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Country"
                            value={CustomerData?.factory_country}
                            onChange={handleFactoryCountryChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Phone"
                            value={CustomerData?.factory_phone}
                            onChange={handleFactoryPhoneChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Mobile"
                            value={CustomerData?.factory_mobile}
                            onChange={handleFactoryMobileChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Email"
                            value={CustomerData?.factory_email}
                            onChange={handleFactoryEmailChange}
                            sx={{ m: 1, width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Container>
            </CardContent>
          </DivBox>
        </Grid>
        <Grid item xs={12} md={2}>
          <InfoBox>
            <ListItemIcon style={{ margin: '15px', minWidth: '35px' }}>
              <StyledIconButton size="small" isSelected={selectedMode == 'History' ? true : false} onClick={() => handleInfoModeChange("History")}>
                <StyledIcon component={ManageSearchIcon} isSelected={selectedMode == 'History' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon>
              <StyledIconButton size="small" isSelected={selectedMode == 'Insights' ? true : false} onClick={() => handleInfoModeChange("Insights")}>
                <StyledIcon component={InsightsIcon} isSelected={selectedMode == 'Insights' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon style={{ float: 'right', marginTop: '8px' }}>
              <IconButton size="large">
                <AspectRatioIcon />
              </IconButton>
            </ListItemIcon>
            <Divider />
            {selectedMode == 'History' ? <GridView columns={columns} rows={customerlist} /> : <Insights />}
          </InfoBox>
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;