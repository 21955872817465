import React, { useState } from 'react';
import "./LoginLayout.css";
import { Login } from './Login/Index'
import { Register } from './Register/Index';
import toast, { Toaster } from 'react-hot-toast';

export const LoginLayout = () => {
  const [isLogginActive, setIsLogginActive] = useState(true);

  const togglePage = () => setIsLogginActive(!isLogginActive);

  const alert = (message, type) => {
    if (type === "error")
      toast.error(message);
    else
      toast.success(message);
  }

  return (
    <div className="login-app">
      <div className="login">
        {!isLogginActive && <div
          className="left-side"
          role="button"
          onClick={togglePage}
          onKeyDown={togglePage}
          tabIndex={0}
        >
          <div className="inner-container">
            <div className="text"></div>
          </div>
        </div>}
        {isLogginActive && <div className="container">
          <Login alert={alert} togglePage={togglePage} />
        </div>}
        {!isLogginActive && <div className="container">
          <Register alert={alert} togglePage={togglePage} />
        </div>}
        {isLogginActive && <div
          className="right-side"
          role="button"
          onClick={togglePage}
          onKeyDown={togglePage}
          tabIndex={0}
        >
          <div className="inner-container">
            <div className="text"></div>
          </div>
        </div>}
      </div>
      <Toaster />
    </div>
  );
};

interface RightSideProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export default LoginLayout;