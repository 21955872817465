import React from 'react';

import { ComponentType, useState, useEffect } from 'react';
import {
  lighten, Icon, styled, Grid, IconButton, ListItemIcon,
  Divider, Container, Card, CardActionArea, Typography, Box, CardHeader, CardContent
} from '@mui/material';
import { DivBox, InfoBox } from '../../StyledComponents/styled';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import GridView from '../../components/GridView/index';
import Insights from '../../components/Insights/index';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select'
import { DeliveryChallan as DeliveryChallanType } from '../../types/DeliveryChallan';
import { SaveDeliveryChallanData, GetDeliveryChallanList, GetDeliveryChallanData, GenerateReport, GetDCListForWO, DeleteDeliveryChallan, GetWorkOrderForDCList } from "../../services/dc-service";
import { GetWorkOrderData } from "../../services/work-order-service";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GetItemData } from "../../services/item-service";
import { GetCustomerData } from "../../services/customer-service";
import dayjs, { Dayjs } from 'dayjs';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";

export const DeliveryChallan = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [customerlist, setCustomerlist] = useState([]);
  const [itemlist, setItemlist] = useState([]);
  const [workOrderList, setWorkOrderList] = useState([]);
  const [dcList, setDcList] = useState([]);
  const [selectedMode, setSelectedMode] = useState<String>('History');
  const [DeliveryChallanData, setDeliveryChallanData] = useState<DeliveryChallanType>({
    pk_DeliveryChallanId: 0,
    delivery_challan_type: 'SALES DC',
    pk_CustomerId: 0,
    DCNo: '',
    DCDate: new Date(),
    CustomerAddr: '',
    pk_workorderid: 0,
    WorkOrderDate: new Date(),
    PONo: '',
    PODate: new Date(),
    RGBNo: '',
    RGBDate: '',
    CustDCNo: '',
    CustDCDate: new Date(),
    F4No: '',
    F4Date: '',
    DeliveryChallanItems: [{
      pk_itemId: 0,
      pk_workorderid: 0,
      QtyRecieved: 0,
      QtyDelivered: 0,
      QtyBalance: 0,
      DespatchQty: 0
    }],
  });
  const [age, setAge] = useState('');

  const loadDeliveryChallan = async () => {
    const deliveryChallansList = await GetDeliveryChallanList();

    let dcCnt = 0;
    if (deliveryChallansList.length > 0) {
      const dcno = deliveryChallansList[0].label;
      dcCnt = parseInt(dcno.substring(2, dcno.length));
    }

    const incNo = (100001 + dcCnt).toString();
    const DCNo = "DC" + incNo.substring(1, incNo.length);
    DeliveryChallanData.DCNo = DCNo;
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
    setDcList(deliveryChallansList)
  }

  const loadWorkOrders = async (pk_CustomerId: number) => {
    const workOrdersList = await GetWorkOrderForDCList(pk_CustomerId);
    setWorkOrderList(workOrdersList)
  }

  useEffect(() => {
    const loadCustomers = async () => {
      const itemsdata = await GetCustomerData('');
      setCustomerlist(itemsdata)
    }
    const loadItems = async () => {
      const itemsdata = await GetItemData(0);
      setItemlist(itemsdata.Table)
    }

    loadItems();
    loadCustomers();
    loadWorkOrders(0);
    loadDeliveryChallan();
  }, []);

  const handleDCNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.DCNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handleDCDateChange = (newValue) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.DCDate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handleInfoModeChange = (mode: String) => {
    setSelectedMode(mode);
  }

  const handleCustomerSelect = (event) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.pk_CustomerId = event.value;
    }
    loadWorkOrders(event.value);
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  };

  const handleDCTypeChange = (event) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.delivery_challan_type = event.target.value;
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handleWorkOrderNoChange = async (event) => {
    const DeliveryChallanItems = [];
    for (let i = 0; i < event.length; i++) {
      const workOrderData = await GetWorkOrderData(event[i].id);
      const dCListForWO = await GetDCListForWO(event[i].id);
      for (let i = 0; i < workOrderData.length; i++) {
        const dcListClone = JSON.parse(JSON.stringify(dCListForWO));
        const filterDcListClone = dcListClone.filter(function (el) {
          return el.pk_ItemID == workOrderData[i].pk_ItemID
        });

        const QtyDelivered = filterDcListClone.reduce((a, b) => a + (b["DespatchQty"] || 0), 0);
        const QtyBalance = parseInt(workOrderData[i].Quantity) - parseInt(QtyDelivered);
        if (QtyBalance > 0) {
          DeliveryChallanItems.push(
            {
              pk_itemId: workOrderData[i].pk_ItemID,
              pk_workorderid: workOrderData[i].pk_workorderid,
              QtyRecieved: workOrderData[i].Quantity,
              QtyDelivered: QtyDelivered,
              QtyBalance: QtyBalance,
              DespatchQty: 0
            }
          )
        }
      }
      DeliveryChallanData.pk_CustomerId = workOrderData[0].pk_customerid;
      DeliveryChallanData.CustDCNo = workOrderData[0].DCNo;
      DeliveryChallanData.CustDCDate = new Date(workOrderData[0].DCDate);
      DeliveryChallanData.PODate = new Date(workOrderData[0].PODate);
      DeliveryChallanData.PONo = workOrderData[0].PONo;
      DeliveryChallanData.DeliveryChallanItems = DeliveryChallanItems;

    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handleWorkOrderDateChange = (newValue) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.WorkOrderDate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handlePONoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.PONo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handlePODateChange = (newValue) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.PODate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handleCustDCNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.CustDCNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handleCustDCDateChange = (newValue) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.CustDCDate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handleItemSelect = (event, key) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.DeliveryChallanItems[key].pk_itemId = event.value;
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  };

  const handleQuantityRecievedChange = (event, key) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.DeliveryChallanItems[key].QtyRecieved = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  };

  const handleQuantityDeliveredChange = (event, key) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.DeliveryChallanItems[key].QtyDelivered = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  };

  const handleBalanceChange = (event, key) => {
    if (DeliveryChallanData != undefined) {
      DeliveryChallanData.DeliveryChallanItems[key].QtyBalance = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  };

  const handleDespatchQtyChange = (event, key) => {
    if (DeliveryChallanData != undefined) {
      if (parseFloat(event.currentTarget.value) > DeliveryChallanData.DeliveryChallanItems[key].QtyBalance) {
        DeliveryChallanData.DeliveryChallanItems[key].DespatchQty = 0;
        toast.error("Despatch Qty should be less than or equal to the Balance");
      }
      else {
        DeliveryChallanData.DeliveryChallanItems[key].DespatchQty = event.currentTarget.value;
      }
    }

    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  };

  const handleDeleteRow = (key) => {
    let newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    newData.DeliveryChallanItems.splice(key, 1);
    setDeliveryChallanData(newData);
  }

  const handleAddRow = () => {
    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    newData.DeliveryChallanItems.push({
      pk_itemId: 0,
      QtyRecieved: 0,
      QtyDelivered: 0,
      QtyBalance: 0,
      Desc: ''
    })
    setDeliveryChallanData(newData);
  }

  const work_order_types = [
    { value: 'SALES DC', label: 'SALES DC' },
    { value: 'RE-WORK DC', label: 'RE-WORK DC' },
    { value: 'SCRAP DC', label: 'SCRAP DC' },
    { value: 'REJECTION DC', label: 'REJECTION DC' }
  ];

  const handleSaveClick = async () => {
    const filterDCItemWithDesQty = DeliveryChallanData.DeliveryChallanItems.filter(function (el) {
      return el.DespatchQty > 0
    });
    if (filterDCItemWithDesQty.length > 0) {
      const data = await SaveDeliveryChallanData(DeliveryChallanData);
      if (data != undefined && data.succeeded) {
        toast.success('Delivery Challan Saved Successfully!');
        await setDeliveryChallanData({
          pk_DeliveryChallanId: 0,
          delivery_challan_type: 'SALES DC',
          pk_CustomerId: 0,
          DCNo: '',
          DCDate: new Date(),
          CustomerAddr: '',
          pk_workorderid: 0,
          WorkOrderDate: new Date(),
          PONo: '',
          PODate: new Date(),
          RGBNo: '',
          RGBDate: '',
          CustDCNo: '',
          CustDCDate: new Date(),
          F4No: '',
          F4Date: '',
          DeliveryChallanItems: [{
            pk_itemId: 0,
            pk_workorderid: 0,
            QtyRecieved: 0,
            QtyDelivered: 0,
            QtyBalance: 0,
            DespatchQty: 0
          }],
        });
        loadDeliveryChallan();
      }
      else {
        toast.error('Error in creating Delivery Challan please try again!');
      }
    }
    else {
      toast.error('Please add some items before saving!');
    }
  }

  const handleClearAll = () => {
    toast.success('Delivery Challan Data Cleared!');
    setDeliveryChallanData({
      pk_DeliveryChallanId: 0,
      delivery_challan_type: 'SALES DC',
      pk_CustomerId: 0,
      DCNo: DeliveryChallanData.DCNo,
      DCDate: new Date(),
      CustomerAddr: '',
      pk_workorderid: 0,
      WorkOrderDate: new Date(),
      PONo: '',
      PODate: new Date(),
      RGBNo: '',
      RGBDate: '',
      CustDCNo: '',
      CustDCDate: new Date(),
      F4No: '',
      F4Date: '',
      DeliveryChallanItems: [{
        pk_itemId: 0,
        pk_workorderid: 0,
        QtyRecieved: 0,
        QtyDelivered: 0,
        QtyBalance: 0,
        DespatchQty: 0
      }],
    });
  }

  const handleHistoryOnclick = async (id) => {
    const deliveryChallanData = await GetDeliveryChallanData(id);

    const DeliveryChallanItems = [];
    for (let i = 0; i < deliveryChallanData.length; i++) {
      DeliveryChallanItems.push(
        {
          pk_itemId: deliveryChallanData[i].pk_ItemID,
          QtyRecieved: deliveryChallanData[i].QtyRecieved,
          QtyDelivered: deliveryChallanData[i].QtyDelivered,
          QtyBalance: deliveryChallanData[i].QtyBalance,
          DespatchQty: deliveryChallanData[i].DespatchQty,
        }
      )
    }

    DeliveryChallanData.pk_DeliveryChallanId = deliveryChallanData[0].pk_DeliveryChallanId;
    DeliveryChallanData.delivery_challan_type = deliveryChallanData[0].delivery_challan_type;
    DeliveryChallanData.pk_CustomerId = parseInt(deliveryChallanData[0].pk_CustomerId);
    DeliveryChallanData.DCNo = deliveryChallanData[0].DCNo;
    DeliveryChallanData.DCDate = deliveryChallanData[0].DCDate;
    DeliveryChallanData.pk_workorderid = deliveryChallanData[0].pk_workorderid;
    DeliveryChallanData.WorkOrderDate = deliveryChallanData[0].WorkOrderDate;
    DeliveryChallanData.PONo = deliveryChallanData[0].PONo;
    DeliveryChallanData.PODate = deliveryChallanData[0].PODate;
    DeliveryChallanData.RGBNo = deliveryChallanData[0].RGBNo;
    DeliveryChallanData.RGBDate = deliveryChallanData[0].RGBDate;
    DeliveryChallanData.CustDCNo = deliveryChallanData[0].CustDCNo;
    DeliveryChallanData.CustDCDate = deliveryChallanData[0].CustDCDate;
    DeliveryChallanData.F4No = deliveryChallanData[0].F4No;
    DeliveryChallanData.F4Date = deliveryChallanData[0].F4Date;

    DeliveryChallanData.DeliveryChallanItems = DeliveryChallanItems;

    const newData = JSON.parse(JSON.stringify(DeliveryChallanData));
    setDeliveryChallanData(newData);
  }

  const handleDownloadReportOnclick = async (id) => {
    GenerateReport(id);
  }

  const handleDeleteDeliveryChallanOnclick = async (id) => {
    const data = await DeleteDeliveryChallan(id);
    if (data != undefined && data.succeeded) {
      toast.success('Delivery Challan Deleted!');
      loadDeliveryChallan();
    }
  }

  const columns = [
    {
      field: 'dcno',
      headerName: 'Delivery Challan No',
      width: 100,
      renderCell: (params) => (
        <>
          <span>
            {params.row.label}
          </span>
        </>
      )
    },
    {
      field: 'report',
      headerName: 'Report',
      width: 150,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDownloadReportOnclick(params.row.value)} className="btn btn-link">
            Download Report
          </button>
        </>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDeleteDeliveryChallanOnclick(params.row.value)} className="btn btn-link">
            Delete DeliveryChallan
          </button>
        </>
      )
    }
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DivBox>
            <CardHeader
              action={
                <ListItemIcon>
                  <IconButton size="large">
                    <SaveIcon onClick={handleSubmit(handleSaveClick)} />
                  </IconButton>
                  <IconButton size="large">
                    <ClearAllIcon onClick={handleClearAll} />
                  </IconButton>
                </ListItemIcon>
              }
              title="Delivery Challan Entry"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          options={work_order_types}
                          value={work_order_types.filter(function (option) {
                            return option.value === DeliveryChallanData.delivery_challan_type;
                          })}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={(event) => handleDCTypeChange(event)} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          placeholder={<div>Type to search customer</div>}
                          options={customerlist}
                          value={customerlist.filter(function (option) {
                            return option.value === DeliveryChallanData.pk_CustomerId;
                          })}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={(event) => handleCustomerSelect(event)} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="D.C.No."
                        value={DeliveryChallanData?.DCNo}
                        onChange={handleDCNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="D.C. Date"
                        inputFormat="DD/MM/YYYY"
                        value={DeliveryChallanData?.DCDate}
                        onChange={handleDCDateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Card style={{ marginTop: '25px' }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              disabled
                              id="outlined-required"
                              label="Address"
                              value={DeliveryChallanData?.CustomerAddr}
                              multiline
                              rows={1}
                              sx={{ m: 1, width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card style={{ marginTop: '25px' }}>
                    <CardActionArea>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormControl fullWidth style={{ margin: '8px' }}>
                              <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={workOrderList}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                onChange={(event) => handleWorkOrderNoChange(event)} />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              label="Work Order Date"
                              inputFormat="DD/MM/YYYY"
                              value={DeliveryChallanData?.WorkOrderDate}
                              onChange={handleWorkOrderDateChange}
                              renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              disabled
                              required
                              id="outlined-required"
                              label="P.O. No."
                              value={DeliveryChallanData?.PONo}
                              onChange={handlePONoChange}
                              sx={{ m: 1, width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              disabled
                              label="P.O. Date"
                              inputFormat="DD/MM/YYYY"
                              value={DeliveryChallanData?.PODate}
                              onChange={handlePODateChange}
                              renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              disabled
                              required
                              id="outlined-required"
                              label="Cust. D.C. No."
                              value={DeliveryChallanData?.CustDCNo}
                              onChange={handleCustDCNoChange}
                              sx={{ m: 1, width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              disabled
                              label="Cust DC Date"
                              inputFormat="DD/MM/YYYY"
                              value={DeliveryChallanData?.CustDCDate}
                              onChange={handleCustDCDateChange}
                              renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <table className={"table"} style={{ width: '1500px' }}>
                            <thead>
                              <tr>
                                <th style={{ width: "10%" }}>S.No</th>
                                <th style={{ width: "35%" }}>Item</th>
                                <th style={{ width: "10%" }}>Work Order</th>
                                <th style={{ width: "10%" }}>Quantity Recieved</th>
                                <th style={{ width: "10%" }}>Quantity Delivered</th>
                                <th style={{ width: "10%" }}>Balance</th>
                                <th style={{ width: "10%" }}>Despatch Qty</th>
                                <th style={{ width: "5%" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {DeliveryChallanData?.DeliveryChallanItems.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {itemlist != undefined && itemlist.length > 0 &&
                                        <Select
                                          options={itemlist}
                                          value={itemlist.filter(function (option) {
                                            return option.value === val.pk_itemId;
                                          })}
                                          onChange={(event) => handleItemSelect(event, key)} />
                                      }
                                    </td>
                                    <td>{val.pk_workorderid}</td>
                                    <td><input type="text" className="form-control" value={val.QtyRecieved} onChange={(event) => handleQuantityRecievedChange(event, key)} /></td>
                                    <td><input type="text" className="form-control" value={val.QtyDelivered} onChange={(event) => handleQuantityDeliveredChange(event, key)} /></td>
                                    <td><input type="text" className="form-control" value={val.QtyBalance} onChange={(event) => handleBalanceChange(event, key)} /></td>
                                    <td><input type="text" className="form-control" value={val.DespatchQty} onChange={(event) => handleDespatchQtyChange(event, key)} /></td>
                                    <td><input type="button" className="btn btn-danger" value="Delete" onClick={() => handleDeleteRow(key)} /></td>
                                  </tr>
                                )
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={5}><input type="button" className="btn btn-danger" value="Add New Row" onClick={handleAddRow} /></td>
                              </tr>
                            </tfoot>
                          </table>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Container>
              </LocalizationProvider>
            </CardContent>
          </DivBox>
        </Grid>
        <Grid item xs={12} md={2}>
          <InfoBox>
            <ListItemIcon style={{ margin: '15px', minWidth: '35px' }}>
              <StyledIconButton size="small" isSelected={selectedMode == 'History' ? true : false} onClick={() => handleInfoModeChange("History")}>
                <StyledIcon component={ManageSearchIcon} isSelected={selectedMode == 'History' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon>
              <StyledIconButton size="small" isSelected={selectedMode == 'Insights' ? true : false} onClick={() => handleInfoModeChange("Insights")}>
                <StyledIcon component={InsightsIcon} isSelected={selectedMode == 'Insights' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon style={{ float: 'right', marginTop: '8px' }}>
              <IconButton size="large">
                <AspectRatioIcon />
              </IconButton>
            </ListItemIcon>
            <Divider />
            {selectedMode == 'History' ? <GridView columns={columns} rows={dcList} /> : <Insights />}
          </InfoBox>
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;