import React, { ComponentType, useState, useEffect } from 'react';
import { Grid, IconButton, ListItemIcon, Divider, Container, styled, lighten, Icon, CardHeader, CardContent } from '@mui/material';
import { DivBox, InfoBox } from '../../StyledComponents/styled';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import {useSelector} from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";
import { Item as ItemType } from '../../types/item';
import { SaveItemData, GetItemData, DeleteItems } from "../../services/item-service";
import SaveIcon from '@mui/icons-material/Save';
import Select from 'react-select'
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import GridView from '../../components/GridView/index';
import Insights from '../../components/Insights/index';

export const Item = () => {
  const username = useSelector(state => state["users"].email)
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [selectedMode, setSelectedMode] = useState<String>('History');

  const [itemlist, setItemlist] = useState([]);

  const loadItems = async () => {
    const itemsdata = await GetItemData(0);
    setItemlist(itemsdata.Table)
  }

  useEffect(() => {
    loadItems();
  }, [])

  const [ItemData, setItemData] = useState<ItemType>({
    pk_itemid: 0,
    part_no: '',
    item_name: '',    
    item_alias: '',
    HSNCode: '',
    item_rate: '',
    unit: '',
    item_desc: '',
    CreatedBy: username,
    itemprops: [{
      pk_itempropsid: 0,
      pk_itemid: 0,
      proposition_item: 0,
      proposition: "",
      proposition_description: "",
      CreatedBy: username
    }],
  });

  const handlePartNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ItemData != undefined) {
      ItemData.part_no = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handleItemNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ItemData != undefined) {
      ItemData.item_name = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handleItemAliasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ItemData != undefined) {
      ItemData.item_alias = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handleHSNCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ItemData != undefined) {
      ItemData.HSNCode = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handleItemRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ItemData != undefined) {
      ItemData.item_rate = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ItemData != undefined) {
      ItemData.unit = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handleItemDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ItemData != undefined) {
      ItemData.item_desc = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handlePropositionChange = (event: React.ChangeEvent<HTMLInputElement>, key) => {
    if (ItemData != undefined) {
      ItemData.itemprops[key].proposition = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handlePropositionDescChange = (event: React.ChangeEvent<HTMLInputElement>, key) => {
    if (ItemData != undefined) {
      ItemData.itemprops[key].proposition_description = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  }

  const handleDeleteRow = (key) => {
    let newData = JSON.parse(JSON.stringify(ItemData));
    newData.itemprops.splice(key, 1);
    setItemData(newData);
  }

  const handleAddRow = () => {
    const newData = JSON.parse(JSON.stringify(ItemData));
    newData.itemprops.push({
      pk_itempropsid: 0,
      pk_itemid: 0,
      proposition_item: 0,
      proposition: "",
      proposition_description: "",
      CreatedBy: username
    })
    setItemData(newData);
  }

  const handleSaveClick = async () => {
    let data = await SaveItemData(ItemData);
    if (data != undefined && data.succeeded) {
      toast.success('Successfully created Item!');
      setItemData({
        pk_itemid: 0,
        part_no: '',
        item_name: '',
        item_alias: '',
        HSNCode: '',
        item_rate: '',
        unit: '',
        item_desc: '',
        CreatedBy: username,
        itemprops: [{
          pk_itempropsid: 0,
          pk_itemid: 0,
          proposition_item: 0,
          proposition: "",
          proposition_description: "",
          CreatedBy: username
        }],
      });
      const itemsdata = await GetItemData(0);
      setItemlist(itemsdata.Table)
    }
    else {
      toast.error('Error in creating Item please try again!');
    }
  }

  const handleItemSelect = (event, key) => {
    if (ItemData != undefined) {
      ItemData.itemprops[key].proposition_item = event.value;
    }
    const newData = JSON.parse(JSON.stringify(ItemData));
    setItemData(newData);
  };

  const handleInfoModeChange = (mode: String) => {
    setSelectedMode(mode);
  }

  const handleHistoryOnclick = async (id) => {
    const itemsdata = await GetItemData(id);
    let itemdata = itemsdata.Table[0];
    if (itemsdata.Table1.length > 0)
      itemdata.itemprops = itemsdata.Table1;
    else {
      itemdata.itemprops = [{
        pk_itempropsid: 0,
        pk_itemid: 0,
        proposition_item: 0,
        proposition: "",
        proposition_description: "",
        CreatedBy: username
      }];
    }
    setItemData(itemdata);
  }

  const handleDeleteItemOnclick = async (id) => {
    if (confirm('Are you sure. You want to delete this item.?') == true) {
      const data = await DeleteItems(id);
      if (data != undefined && data.succeeded) {
        toast.success('Item Deleted!');
        loadItems();
      }
    } 
  }

  const columns = [
    {
      field: 'label',
      headerName: 'Name',
      width: 150,
      renderCell: (params) => (
        <button onClick={() => handleHistoryOnclick(params.row.value)} className="btn btn-link">
          {params.row.label}
        </button>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDeleteItemOnclick(params.row.value)} className="btn btn-link">
            Delete Item
          </button>
        </>
      )
    }
  ];
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DivBox>
            <CardHeader
              action={
                <ListItemIcon>
                  <IconButton size="large">
                    <SaveIcon onClick={handleSubmit(handleSaveClick)} />
                  </IconButton>
                </ListItemIcon>
              }
              title="Item Master"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Part No"
                      sx={{ m: 1, width: '100%' }}
                      value={ItemData?.part_no}
                      {...register("part_no", { required: true })}
                      onChange={handlePartNoChange}
                    />
                    {errors.part_no && errors.part_no.type === "required" && <span style={{ color: 'red', marginLeft: '10px' }}>This is required</span>}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Item Name"
                      sx={{ m: 1, width: '100%' }}
                      value={ItemData?.item_name}
                      {...register("itemname", { required: true })}
                      onChange={handleItemNameChange}
                    />
                    {errors.itemname && errors.itemname.type === "required" && <span style={{ color: 'red', marginLeft: '10px' }}>This is required</span>}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Item Alias Name"
                      sx={{ m: 1, width: '100%' }}
                      value={ItemData?.item_alias}
                      onChange={handleItemAliasChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="HSN Code"
                      sx={{ m: 1, width: '100%' }}
                      value={ItemData?.HSNCode}
                      onChange={handleHSNCodeChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Item Rate"
                      sx={{ m: 1, width: '100%' }}
                      value={ItemData?.item_rate}
                      onChange={handleItemRateChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Unit"
                      sx={{ m: 1, width: '100%' }}
                      value={ItemData?.unit}
                      onChange={handleUnitChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="item-description"
                      label="Item Description"
                      multiline
                      rows={4}
                      sx={{ m: 1, width: '100%' }}
                      value={ItemData?.item_desc}
                      onChange={handleItemDescChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Item Propositions</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <table className={"table"}>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Item</th>
                          <th>Proposition</th>
                          <th>Description</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {ItemData?.itemprops.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>
                                {itemlist != undefined && itemlist.length > 0 &&
                                  <Select
                                    options={itemlist}
                                    value={itemlist.filter(function (option) {
                                      return option.value === val.proposition_item;
                                    })}
                                    onChange={(event) => handleItemSelect(event, key)} />
                                }
                              </td>
                              <td><input onChange={(event) => handlePropositionChange(event, key)} className={"form-control"} value={val.proposition} id="txtProposition" /></td>
                              <td><input onChange={(event) => handlePropositionDescChange(event, key)} className={"form-control"} value={val.proposition_description} id="txtDescription" /></td>
                              <th><input type="button" className="btn btn-danger" value="Delete" onClick={() => handleDeleteRow(key)} /></th>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={5}><input type="button" className="btn btn-danger" value="Add New Row" onClick={handleAddRow} /></td>
                        </tr>
                      </tfoot>
                    </table>
                  </Grid>
                </Grid>
              </Container>
            </CardContent>
          </DivBox>
        </Grid>
        <Grid item xs={12} md={2}>
          <InfoBox>
            <ListItemIcon style={{ margin: '15px', minWidth: '35px' }}>
              <StyledIconButton size="small" isSelected={selectedMode == 'History' ? true : false} onClick={() => handleInfoModeChange("History")}>
                <StyledIcon component={ManageSearchIcon} isSelected={selectedMode == 'History' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon>
              <StyledIconButton size="small" isSelected={selectedMode == 'Insights' ? true : false} onClick={() => handleInfoModeChange("Insights")}>
                <StyledIcon component={InsightsIcon} isSelected={selectedMode == 'Insights' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon style={{ float: 'right', marginTop: '8px' }}>
              <IconButton size="large">
                <AspectRatioIcon />
              </IconButton>
            </ListItemIcon>
            <Divider />
            {selectedMode == 'History' ? <GridView columns={columns} rows={itemlist} /> : <Insights />}
          </InfoBox>
        </Grid>
      </Grid>      
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;