import { createSlice } from '@reduxjs/toolkit'

export const commonReducers = createSlice({
  name: 'common',
  initialState: {
    isLoading: false,
    isMenuToggle: true
  },
  reducers: {
    common: (state, action) => {
      state.isLoading = action.payload.isLoading;
    }
  },
})

// Action creators are generated for each case reducer function
export const { common } = commonReducers.actions

export default commonReducers.reducer