import React from 'react';

import { ComponentType, useState, useEffect } from 'react';
import {
  lighten, Icon, styled, Grid, IconButton, ListItemIcon,
  Divider, Container, Card, CardActionArea, CardContent, Typography, Box, CardHeader
} from '@mui/material';
import { DivBox, InfoBox } from '../../StyledComponents/styled';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TextField from '@mui/material/TextField';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import GridView from '../../components/GridView/index';
import Insights from '../../components/Insights/index';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select'
import { InspectionReport as InspectionReportType } from '../../types/InspectionReport';
import { SaveInspectionReportData, GenerateReport, GetInspectionReportList, GetInspectionReportData } from "../../services/inspection-report-service";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GetItemData } from "../../services/item-service";
import { GetCustomerData } from "../../services/customer-service";
import { get_financial_year } from '../../utils/common';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";

export const InspectionReport = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [customerlist, setCustomerlist] = useState([]);
  const [itemlist, setItemlist] = useState([]);
  const [inspectionReportList, setInspectionReportList] = useState([]);
  const [selectedMode, setSelectedMode] = useState<String>('History');
  const [InspectionReportData, setInspectionReportData] = useState<InspectionReportType>({
    pk_InspectionReportId: 0,
    InspectionNo: '',
    InspectionDate: new Date(),
    pk_ItemId: 0,
    pk_CustomerId: 0,
    Qty: '',
    VisualDefectSpec: '',
    VisualDefect1: 'OK',
    VisualDefect2: 'OK',
    VisualDefect3: 'OK',
    VisualDefect4: 'OK',
    VisualDefect5: 'OK',
    VisualDefectRemarks: '',
    CoatThickSpec: '',
    CoatThick1: '',
    CoatThick2: '',
    CoatThick3: '',
    CoatThick4: '',
    CoatThick5: '',
    CoatThickRemarks: '',
    AdhesionTestSpec: 'Free from peel-off',
    AdhesionTest1: '',
    AdhesionTest2: '',
    AdhesionTest3: '',
    AdhesionTest4: '',
    AdhesionTest5: '',
    AdhesionTestRemarks: '',
    PencHardSpec: '1HB',
    PencHard1: '',
    PencHard2: '',
    PencHard3: '',
    PencHard4: '',
    PencHard5: '',
    PencHardRemarks: ''
  });
  const [age, setAge] = useState('');

  const loadInspectionReport = async () => {
    const inspectionReportsList = await GetInspectionReportList();

    const finYear = get_financial_year();

    const inspoicesListForFY = inspectionReportsList.filter(function (row) {
      return row.label.endsWith(finYear);
    });

    let inspCnt = 0;

    if (inspoicesListForFY.length > 0) {
      const inspectionno = inspoicesListForFY.reduce(function (prev, current) {
        return (prev.id > current.id) ? prev : current
      }).label;
      inspCnt = parseInt(inspectionno.substring(0, inspectionno.indexOf('/')));
    }

    const incNo = (100001 + inspectionReportsList.length).toString();
    const InspectionNo = incNo.substring(1, incNo.length) + '/' + finYear;

    InspectionReportData.InspectionNo = InspectionNo;
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
    setInspectionReportList(inspectionReportsList)
  }

  useEffect(() => {
    const loadCustomers = async () => {
      const itemsdata = await GetCustomerData('');
      setCustomerlist(itemsdata)
    }
    const loadItems = async () => {
      const itemsdata = await GetItemData(0);
      setItemlist(itemsdata.Table)
    }
    loadItems();
    loadCustomers();
    loadInspectionReport();
  }, []);

  const handleInspectionNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.InspectionNo = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  }

  const handleInspectionDateChange = (newValue) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.InspectionDate = new Date(newValue);
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  }

  const handleInfoModeChange = (mode: String) => {
    setSelectedMode(mode);
  }

  const handleItemSelect = async (event) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.pk_ItemId = event.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleCustomerSelect = (event) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.pk_CustomerId = event.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.Qty = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleVisualDefect1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.VisualDefect1 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleVisualDefect2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.VisualDefect2 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleVisualDefect3Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.VisualDefect3 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleVisualDefect4Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.VisualDefect4 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleVisualDefect5Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.VisualDefect5 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleVisualDefectRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.VisualDefectRemarks = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleCoatThickSpecChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.CoatThickSpec = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleCoatThick1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.CoatThick1 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleCoatThick2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.CoatThick2 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleCoatThick3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.CoatThick3 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleCoatThick4Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.CoatThick4 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleCoatThick5Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.CoatThick5 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleCoatThickRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.CoatThickRemarks = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleAdhesionTestSpecChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.AdhesionTestSpec = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleAdhesionTest1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.AdhesionTest1 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleAdhesionTest2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.AdhesionTest2 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleAdhesionTest3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.AdhesionTest3 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleAdhesionTest4Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.AdhesionTest4 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleAdhesionTest5Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.AdhesionTest5 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleAdhesionTestRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.AdhesionTestRemarks = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handlePencHardSpecChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.PencHardSpec = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handlePencHard1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.PencHard1 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handlePencHard2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.PencHard2 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handlePencHard3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.PencHard3 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handlePencHard4Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.PencHard4 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handlePencHard5Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.PencHard5 = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handlePencHardRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InspectionReportData != undefined) {
      InspectionReportData.PencHardRemarks = event.currentTarget.value;
    }
    const newData = JSON.parse(JSON.stringify(InspectionReportData));
    setInspectionReportData(newData);
  };

  const handleSaveClick = async () => {
    const data = await SaveInspectionReportData(InspectionReportData);
    if (data != undefined && data.succeeded) {
      toast.success('Inspection Report Saved Successfully!');      
      loadInspectionReport();
      setInspectionReportData({
        pk_InspectionReportId: 0,
        InspectionNo: '',
        InspectionDate: new Date(),
        pk_ItemId: 0,
        pk_CustomerId: 0,
        Qty: '',
        VisualDefectSpec: '',
        VisualDefect1: 'OK',
        VisualDefect2: 'OK',
        VisualDefect3: 'OK',
        VisualDefect4: 'OK',
        VisualDefect5: 'OK',
        VisualDefectRemarks: '',
        CoatThickSpec: '',
        CoatThick1: '',
        CoatThick2: '',
        CoatThick3: '',
        CoatThick4: '',
        CoatThick5: '',
        CoatThickRemarks: '',
        AdhesionTestSpec: '',
        AdhesionTest1: '',
        AdhesionTest2: '',
        AdhesionTest3: '',
        AdhesionTest4: '',
        AdhesionTest5: '',
        AdhesionTestRemarks: '',
        PencHardSpec: '',
        PencHard1: '',
        PencHard2: '',
        PencHard3: '',
        PencHard4: '',
        PencHard5: '',
        PencHardRemarks: ''
      });
    }
    else {
      toast.error('Error in creating Inspection Report please try again!');
    }
  }

  const handleDownloadReportOnclick = async (id) => {
    GenerateReport(id);
  }

  const handleClearAll = () => {
    toast.success('Inspection Report Data Cleared!');
    setInspectionReportData({
      pk_InspectionReportId: 0,
      InspectionNo: '',
      InspectionDate: new Date(),
      pk_ItemId: 0,
      pk_CustomerId: 0,
      Qty: '',
      VisualDefectSpec: '',
      VisualDefect1: 'OK',
      VisualDefect2: 'OK',
      VisualDefect3: 'OK',
      VisualDefect4: 'OK',
      VisualDefect5: 'OK',
      VisualDefectRemarks: '',
      CoatThickSpec: '',
      CoatThick1: '',
      CoatThick2: '',
      CoatThick3: '',
      CoatThick4: '',
      CoatThick5: '',
      CoatThickRemarks: '',
      AdhesionTestSpec: '',
      AdhesionTest1: '',
      AdhesionTest2: '',
      AdhesionTest3: '',
      AdhesionTest4: '',
      AdhesionTest5: '',
      AdhesionTestRemarks: '',
      PencHardSpec: '',
      PencHard1: '',
      PencHard2: '',
      PencHard3: '',
      PencHard4: '',
      PencHard5: '',
      PencHardRemarks: ''
    });
  }

  const handleHistoryOnclick = async (id) => {
    const inspectionReportsData = await GetInspectionReportData(id);
    setInspectionReportData(inspectionReportsData[0]);
  }

  const columns = [
    {
      field: 'insp-no',
      headerName: 'Inspection Report No',
      width: 150,
      renderCell: (params) => (
        <span>
          {params.row.label}
        </span>
      ),
    },
    {
      field: 'report',
      headerName: 'Report',
      width: 150,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDownloadReportOnclick(params.row.value)} className="btn btn-link">
            Download Report
          </button>
        </>
      )
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DivBox>
            <CardHeader
              action={
                <ListItemIcon>
                  <IconButton size="large">
                    <SaveIcon onClick={handleSubmit(handleSaveClick)} />
                  </IconButton>
                  <IconButton size="large">
                    <ClearAllIcon onClick={handleClearAll} />
                  </IconButton>
                </ListItemIcon>
              }
              title="Inspection Report Entry"
              subheader=""
            />
            <Divider />
            <CardContent style={{ paddingTop: '50px', maxWidth: '2000px', height: '90%', overflowY: 'scroll' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Inspection No."
                        value={InspectionReportData?.InspectionNo}
                        onChange={handleInspectionNoChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Inspection Date"
                        inputFormat="DD/MM/YYYY"
                        value={InspectionReportData?.InspectionDate}
                        onChange={handleInspectionDateChange}
                        renderInput={(params) => <TextField {...params} error={false} sx={{ m: 1, width: '100%' }} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          placeholder={<div>Type to search items</div>}
                          options={itemlist}
                          value={itemlist.filter(function (option) {
                            return option.value === InspectionReportData.pk_ItemId;
                          })}
                          onChange={(event) => handleItemSelect(event)}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth style={{ margin: '8px' }}>
                        <Select
                          placeholder={<div>Type to search customer</div>}
                          options={customerlist}
                          value={customerlist.filter(function (option) {
                            return option.value === InspectionReportData.pk_CustomerId;
                          })}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={(event) => handleCustomerSelect(event)} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Production Qty"
                        value={InspectionReportData?.Qty}
                        onChange={handleQtyChange}
                        sx={{ m: 1, width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th rowSpan={2} style={{ width: '10%' }} >
                            VISUAL DEFECT
                          </th>
                          <th style={{ width: '30%' }}>
                            Specification
                          </th>
                          <th style={{ width: '10%' }}>
                            1
                          </th>
                          <th style={{ width: '10%' }}>
                            2
                          </th>
                          <th style={{ width: '10%' }}>
                            3
                          </th>
                          <th style={{ width: '10%' }}>
                            4
                          </th>
                          <th style={{ width: '10%' }}>
                            5
                          </th>
                          <th style={{ width: '10%' }}>
                            Remarks
                          </th>
                        </tr>
                        <tr>
                          <th>
                            Free from stain marks, Pin Holes, Patches, Blisters, Dust, Roughness etc.
                          </th>
                          <th>
                            <select className='form-control' value={InspectionReportData?.VisualDefect1} onChange={(e) => handleVisualDefect1Change(e)}>
                              <option value="OK">OK</option>
                              <option value="Not Ok">Not OK</option>
                            </select>
                          </th>
                          <th>                            
                          <select className='form-control' value={InspectionReportData?.VisualDefect2} onChange={(e) => handleVisualDefect2Change(e)}>
                              <option value="OK">OK</option>
                              <option value="Not Ok">Not OK</option>
                            </select>
                          </th>
                          <th>
                            <select className='form-control' value={InspectionReportData?.VisualDefect3} onChange={(e) => handleVisualDefect3Change(e)}>
                              <option value="OK">OK</option>
                              <option value="Not Ok">Not OK</option>
                            </select>
                          </th>
                          <th>
                            <select className='form-control' value={InspectionReportData?.VisualDefect4} onChange={(e) => handleVisualDefect4Change(e)}>
                              <option value="OK">OK</option>
                              <option value="Not Ok">Not OK</option>
                            </select>
                          </th>
                          <th>
                            <select className='form-control' value={InspectionReportData?.VisualDefect5} onChange={(e) => handleVisualDefect5Change(e)}>
                              <option value="OK">OK</option>
                              <option value="Not Ok">Not OK</option>
                            </select>
                          </th>
                          <th>
                            <input type="text" className="form-control" value={InspectionReportData?.VisualDefectRemarks} onChange={handleVisualDefectRemarksChange} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            COATING THICKNESS
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.CoatThickSpec} onChange={handleCoatThickSpecChange} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.CoatThick1} onChange={handleCoatThick1Change} />
                          </td>
                          <td>        
                            <input type="text" className="form-control" value={InspectionReportData?.CoatThick2} onChange={handleCoatThick2Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.CoatThick3} onChange={handleCoatThick3Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.CoatThick4} onChange={handleCoatThick4Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.CoatThick5} onChange={handleCoatThick5Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.CoatThickRemarks} onChange={handleCoatThickRemarksChange} />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ADHESION TEST
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.AdhesionTestSpec} onChange={handleAdhesionTestSpecChange} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.AdhesionTest1} onChange={handleAdhesionTest1Change} />
                          </td>
                          <td>        
                            <input type="text" className="form-control" value={InspectionReportData?.AdhesionTest2} onChange={handleAdhesionTest2Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.AdhesionTest3} onChange={handleAdhesionTest3Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.AdhesionTest4} onChange={handleAdhesionTest4Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.AdhesionTest5} onChange={handleAdhesionTest5Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.AdhesionTestRemarks} onChange={handleAdhesionTestRemarksChange} />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            PENCIL HARDNESS
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.PencHardSpec} onChange={handlePencHardSpecChange} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.PencHard1} onChange={handlePencHard1Change} />
                          </td>
                          <td>        
                            <input type="text" className="form-control" value={InspectionReportData?.PencHard2} onChange={handlePencHard2Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.PencHard3} onChange={handlePencHard3Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.PencHard4} onChange={handlePencHard4Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.PencHard5} onChange={handlePencHard5Change} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={InspectionReportData?.PencHardRemarks} onChange={handlePencHardRemarksChange} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Container>
              </LocalizationProvider>
            </CardContent>
          </DivBox>
        </Grid>
        <Grid item xs={12} md={2}>
          <InfoBox>
            <ListItemIcon style={{ margin: '15px', minWidth: '35px' }}>
              <StyledIconButton size="small" isSelected={selectedMode == 'History' ? true : false} onClick={() => handleInfoModeChange("History")}>
                <StyledIcon component={ManageSearchIcon} isSelected={selectedMode == 'History' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon>
              <StyledIconButton size="small" isSelected={selectedMode == 'Insights' ? true : false} onClick={() => handleInfoModeChange("Insights")}>
                <StyledIcon component={InsightsIcon} isSelected={selectedMode == 'Insights' ? true : false} />
              </StyledIconButton>
            </ListItemIcon>
            <ListItemIcon style={{ float: 'right', marginTop: '8px' }}>
              <IconButton size="large">
                <AspectRatioIcon />
              </IconButton>
            </ListItemIcon>
            <Divider />
            {selectedMode == 'History' ? <GridView columns={columns} rows={inspectionReportList} /> : <Insights />}
          </InfoBox>
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
};

const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  boxShadow: isSelected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon) <{ isSelected: boolean; component: ComponentType<{}> }>`
  ${({ isSelected, theme }) => isSelected && `color: ${theme.palette.primary.main};`}
`;