import React, { useState } from "react";
import loginImg from "../../../../images/login.svg";
import { RegisterLogin } from "../../../../services/login-service";
import { useStore, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

export const Register = ({alert, togglePage}) => {

  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  let history = useHistory();
  const dispatch = useDispatch();

  const handleUsernameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setUsername(newValue)
  }

  const handleFirstnameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setFirstname(newValue)
  }

  const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setPassword(newValue)
  }

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setEmail(newValue)
  }

  const registerUser = async () => {
    const data = await RegisterLogin(username, firstname, password, email, dispatch);
    if (data != undefined && data.succeeded) {
      alert("Registration Successful.!", "success");
      togglePage();
    }
    else {
      alert("Error in registration. Please contact administrator.!", "error");
    }
  }

  const loginPage = async () => {
    togglePage();
  }

  return (
    <div className="base-container">
      <div className="header">Register</div>
      <div className="content">
        <div className="image">
          <img src={loginImg} alt="" />
        </div>
        <div className="form">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" name="username" placeholder="username" value={username} onChange={handleUsernameChange} />
          </div>
          <div className="form-group">
            <label htmlFor="firstname">First Name</label>
            <input type="text" name="firstname" placeholder="firstname" value={firstname} onChange={handleFirstnameChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" placeholder="email" value={email} onChange={handleEmailChange} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" placeholder="password" value={password} onChange={handlePasswordChange} />
          </div>
        </div>
      </div>
      <div className="">
        <button type="button" className="btn btn-primary" onClick={registerUser}>
          Register
        </button>
          <span style={{display: 'block'}}><i>Already have an accound? <a href="/#" onClick={loginPage}>Login!</a></i></span>
      </div>
    </div>
  );
}
