import { Grid, Box, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';


export const GridViewReport = (props: any) => {

  const rows = props.rows == undefined ? [] : props.rows;
  const columns = props.columns == undefined ? [] : props.columns;

  const downloadReport = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = props.reportTitle;
    const headers = [props.reportHeaders];

    const data = props.reportData;

    doc.text(title, marginLeft, 40);
    autoTable(doc, { html: '#grid-view table' });
    doc.save("report.pdf");
  };

  return (
    <>
      {rows.length > 0 ? <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" style={{ marginTop: '15px', float: 'right' }} onClick={() => downloadReport()}>Download Report</Button>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 400, width: '100%' }}>
            {
              <TableContainer sx={{ maxHeight: 440 }} id="grid-view">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((obj, key) => (
                        <TableCell align="center" key={key}>{obj.headerName}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((rowObj, rowKey) => (
                      <TableRow key={rowKey}>
                        {columns.map((colObj, colKey) => (
                          <TableCell align="center" key={colKey}>{rowObj[colObj.field]}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Box>
        </Grid>
      </Grid> : null}
    </>
  );
};

export default GridViewReport;