import { useMemo, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { PageDefault } from './components/PageDefault';
import { LoginLayout } from './components/Login/Layout/Index';
// import { NotFound } from './components/NotFound/index';
import { AppContext, ThemeModeContext } from './contexts';
import { AppClient } from './clients';
import { routes } from './config';
import { Route as AppRoute } from './types';
import { getAppTheme } from './styles/theme';
import { DARK_MODE_THEME, LIGHT_MODE_THEME } from './utils/constants';
import { useSelector } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

function App() {
  const [mode, setMode] = useState<typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME>(LIGHT_MODE_THEME);
  const appClient = new AppClient();
  const isLoading = useSelector(state => state["common"].isLoading);

  const themeMode = useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode((prevMode) => (prevMode === LIGHT_MODE_THEME ? DARK_MODE_THEME : LIGHT_MODE_THEME));
      },
    }),
    []
  );

  const theme = useMemo(() => getAppTheme(mode), [mode]);

  const addRoute = (route: AppRoute) => (
    <Route key={route.key} path={route.path} component={route.component || PageDefault} exact />
  );

  return (
    <AppContext.Provider value={appClient}>      
      {isLoading && <div id="overlay">
        <div className="spinner"></div>
      </div>}
      <ThemeModeContext.Provider value={themeMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route key={0} path={'/'} component={LoginLayout} exact />
              <Route key={0} path={'/login'} component={LoginLayout} exact />
              <Route>
                <Layout>
                  <Switch>
                  {/* <Route key={0} path={'*'} component={NotFound} exact /> */}
                    {routes.map((route: AppRoute) =>
                      route.subRoutes ? route.subRoutes.map((item: AppRoute) => addRoute(item)) : addRoute(route)
                    )}
                  </Switch>
                </Layout>
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </ThemeModeContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
